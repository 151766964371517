import { useRef, useState, useEffect, useContext } from 'react'
import { select, ascending, transition, easeCubicIn, easeCubicOut } from 'd3'

import Vessel from './vessels/vessels'
import IngredientNode from './ingredients/ingredientNode'
import Action from './actions/actions'
import Plate from './plate/plate'
import { getDatabase, onValue, ref as refdb } from 'firebase/database'
import { Theme } from '../../context/theme'
import { Custom } from '../../context/custom'
import { useWindowSize } from '../4tools/windowsize'

import { themes } from '../../../media/theme/colors.js'
import './viz.css'

const Viz = ({ svgHeight, f, E }) => {
  const size = useWindowSize()

  const { value, setValue } = useContext(Custom)
  const { theme } = useContext(Theme)
  const t = themes[theme]

  var flat = value ? value.flat : []
  const plate = []
  const vessel = []
  const ingredientNodes = []
  const actionNodes = []

  flat.forEach((d, i) => {
    if (!d.depth) {
      plate.push(<Plate root={d} f={f} i={i} />)
    } else if ('vessel' in d) {
      vessel.push(<Vessel d={d} i={i} f={f} />)
    } else if ('ingredient' in d) {
      ingredientNodes.push(
        <IngredientNode f={f} b={d} E={E} s={size.width > 600} />
      )
    } else if ('action' in d) {
      actionNodes.push(<Action f={f} b={d} />)
    }
  })

  return (
    <>
      <section className={'plot'}>
        {value && value.preheat && (
          <div className={'preheat font2'} style={{ color: t.red0 }}>
            preheat <span>{value.preheat}</span>
          </div>
        )}
        <svg
          height={svgHeight + 120}
          width={size.width < 500 ? size.width : 'calc(100vw - 80px)'}
        >
          <g transform={`translate( ${f.mobile ? 15 : 10}, ${40} )`}>
            <defs>
              <filter id='blur' x='0' y='0'>
                <feGaussianBlur in='SourceGraphic' stdDeviation='1.6' />
              </filter>
            </defs>
            {vessel}
            {ingredientNodes}
            {actionNodes}
            {plate}
          </g>
        </svg>
      </section>
    </>
  )
}

export default Viz
