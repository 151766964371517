import React, { useState, useEffect, useContext, useRef } from 'react'
import _ from 'lodash'
import { motion } from 'framer-motion'
import anime from 'animejs'
import format from '../../../recipe/_formats/formatDesktop.json'
import { rgb } from 'd3'

import { getDatabase, onValue, ref, ref as refdb, set } from 'firebase/database'
import { Patron } from '../../../context/patron'
import { Palette } from '../../../context/palette'

import { Theme } from '../../../context/theme'
import { themes } from '../../../../media/theme/colors.js'

import { shakey } from './anime/shakey'
import { hearty } from './anime/hearty'

import './plate.css'

const Love = ({ d }) => {
  const user = useContext(Patron)
  const { palette } = useContext(Palette)
  const { theme } = useContext(Theme)
  const t = themes[theme]
  const [loved, setLoved] = useState(0)
  const [_clap, set_clap] = useState(null)
  const ref = useRef()

  useEffect(() => {
    const db = getDatabase()
    onValue(refdb(db, 'claps/' + d.id), (s) => {
      set_clap(_.size(s.val()))
    })
    // return () => refdb.off()
  }, [])

  // useEffect(() => {
  //   var lo = 0
  //   if (palette.loved) {
  //     lo = palette.loved
  //       .map((i) => i.slice('-')[0])
  //       .map((i) => _.toNumber(i))
  //       .includes(d.id)
  //   }
  //   setLoved(lo)
  // })

  const lovvy = (l) => {
    const db = getDatabase()
    set(
      refdb(db, 'claps/' + d.id + '/' + user.id + '-' + Date.now()),
      Date.now()
    )
    set(
      refdb(db, 'patrons/' + user.uid + '/palette/loved/' + d.id),
      d.id + '-l-' + Date.now()
    )
  }

  return (
    <>
      <motion.button
        onClick={() => {
          setLoved(user ? 1 : 0)
          user ? lovvy(loved) : shakey(ref.current)
        }}
        className='plate-act-heart'
      >
        <motion.svg viewBox='0 0 34 29.7'>
          <motion.path
            ref={ref}
            style={{ transformOrigin: 'center center' }}
            stroke={t.orn0}
            animate={{
              fill: loved ? t.orn0 : 'none',
            }}
            d='M17,27l-.49-.41C5.92,18,3.05,15,3.05,10.05a7.35,7.35,0,0,1,7.39-7.38A8.36,8.36,0,0,1,17,6a8.36,8.36,0,0,1,6.56-3.36A7.35,7.35,0,0,1,31,10.05c0,4.92-2.87,8-13.46,16.57Z'
          />
        </motion.svg>
      </motion.button>
      <motion.div className='plate-act-heart' style={{ color: t.orn0 }}>
        {_clap}
      </motion.div>
    </>
  )
}

export default Love
