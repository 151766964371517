import { motion } from 'framer-motion'
import Navigate from './0navigate/navigate'
import Portion from './4tools/portion'
import List from './2lists/list'
import Viz from './3viz/viz'

import { ReactComponent as NytSVG } from '../../media/externals/nyt.svg'

const Mobile = ({ r, f, t, E }) => {
  return (
    <>
      <motion.div className={'recipe-all'}>
        {/* <Splash recipe={r[0]} t={t} /> */}
        <div className={'recipe-chapters'}>
          <div
            style={{ color: t.txt1 }}
            className={'recipe-forward-title font2'}
          >
            <h1 style={{ color: t.txt1 }}>{r[0].name}</h1>
            <div className={'recipe-forward-src'}>
              <h2>{'by ' + r[0].src_auth}</h2>
              <style>{`.nytsvg{
                  fill: ${t.txt1}
                }`}</style>
              {r[0].src_title == 'NYT Cooking' && (
                <a target='_blank' href={r[0].src_url}>
                  <NytSVG />
                </a>
              )}
            </div>
          </div>
          <div
            style={{ color: t.txt1 }}
            className={'recipe-forward-detail font1'}
          >
            {r[0].foreward}
          </div>
          <main className='recipe'>
            <Navigate recipe={r[0]} />
            <List t={t} f={f} E={E} />
            <Viz svgHeight={r[1]} f={f} t={t} E={E} />
          </main>
        </div>
      </motion.div>
    </>
  )
}

export default Mobile
