import { useEffect, useState, useMemo, useRef, useContext } from 'react'
import { motion } from 'framer-motion'
import { useNavigate, Outlet, Link } from 'react-router-dom'
import { getDatabase, ref as refdb, get, onValue } from 'firebase/database'
import { themes } from '../../../media/theme/colors'
import { Patron } from '../../context/patron'
import { Palette } from '../../context/palette'
import { Theme } from '../../context/theme'
import Logo from './head/items/logo.js'

import Head from './head/head'
import Foot from './head/foot'

import './kitchen.css'

const Kitchen = ({ s }) => {
  const user = useContext(Patron)
  const { theme } = useContext(Theme)
  const { palette, setPalette } = useContext(Palette)
  const t = themes[theme]

  useEffect(() => {
    if (user) {
      const db = getDatabase()
      onValue(refdb(db, 'patrons/' + user.uid + '/palette/'), (s) => {
        setPalette(s.val() ? s.val() : [])
      })
    }
  }, [user])

  const back = `body{
    background: ${t.bak0}
  }
  `

  const scroll = `
    *::-webkit-scrollbar {
    background: ${t.bak0};
    }
    *::-webkit-scrollbar-thumb {
    background: ${t.bak3};
    border-radius: 20px;
    }
    section ::-webkit-scrollbar{
    display: none;
    }
    `
  return (
    <>
      <style>{back}</style>
      {s && <style>{scroll}</style>}
      <Head user={user} />
      <main className='kitchen'>
        <motion.div className='kitchen'>
          <Outlet />
        </motion.div>
      </main>
      <Foot t={t} Logo={<Logo />} />
    </>
  )
}

export default Kitchen
