import { useState, useEffect, useContext, useRef } from 'react'
import { getDatabase, onValue, ref as refdb } from 'firebase/database'
import { motion } from 'framer-motion'
import { themes } from '../../../media/theme/colors'
import { Theme } from '../../context/theme'
import Learn from './welcome/learn/learn'

import Section from './section/section'
import WelcomeM from './welcome/welcomem'
import WelcomeD from './welcome/welcomed'

import './menu.css'

const Menu = ({ s, E }) => {
  const { theme } = useContext(Theme)
  const t = themes[theme]

  const [all, setAll] = useState([])
  useEffect(() => {
    const db = getDatabase()
    onValue(refdb(db, 'recipes/'), (s) => {
      setAll(s.val())
    })
    return () => refdb.off()
  }, [])

  const ref = useRef()

  const section = [{ title: 'american cuisine', dishes: all }]

  return (
    <motion.div>
      <motion.div className='welcome-wrap'>
        <motion.div
          className='welcome'
          transition={{ duration: 0.01 }}
          exit={{ opacity: 0 }}
        >
          {s ? <WelcomeD t={t} E={E} /> : <WelcomeM t={t} E={E} />}
        </motion.div>
        <motion.div
          className={'pronounce font3'}
          style={{ color: t.opp, opacity: 0.7 }}
        >
          viz dot recipes
        </motion.div>
      </motion.div>
      <div className='tutorial-wrap'>
        <motion.div
          className='tutorial font2'
          style={{ color: t.opp, cursor: 'pointer' }}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 0.7 }}
          whileTap={{ letterSpacing: '1px' }}
          whileHover={{ letterSpacing: '2px' }}
          transition={{
            opacity: { duration: 0.8, delay: 0.1 },
            letterSpacing: { type: 'spring', duration: 0.1 },
          }}
          onClick={() =>
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
          }
        >
          tutorial?
        </motion.div>
      </div>
      {section.map((d) => (
        <Section d={d} />
      ))}
      <div ref={ref} style={{ transform: 'translateY(250px)' }}></div>
      <Learn t={t} s={s} />
      <motion.div
        className='about font2'
        style={{ color: t.opp }}
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.1 }}
      >
        viz.recipes introduces a novel recipe format. Part flow diagram, part
        timeline, this data visualization aims to act as an alternative to text
        based recipes. It offers an overview of the process, with details
        arranged further down the hierarchy. <br />
        <br />
        The prevailing recipe standard over accentuates details, detracting from
        the ability to recognize broad cooking concepts. This format encourages
        acquaintance with the fundamentals of cooking. <br />
        <br />* Sourced recipes may be altered slightly to fit the scheme.{' '}
        <br />
        <br />
        My name is Elijah munn. I am a developer and designer. my email is
        elijah@munn.studio
      </motion.div>
    </motion.div>
  )
}

export default Menu
