import { useState, useEffect, useRef, useContext } from 'react'
import { getDatabase, onValue, ref as refdb } from 'firebase/database'
import { motion } from 'framer-motion'
import _ from 'lodash'

import { clean } from '../../../pantry/tools'
import { useSwipeable } from 'react-swipeable'
import { themes } from '../../../../media/theme/colors'
import { Theme } from '../../../context/theme'
import { Custom } from '../../../context/custom'

const IngredientNode = ({ f, b, s }) => {
  const { theme } = useContext(Theme)
  const { value, setValue } = useContext(Custom)
  const [icon, setIcon] = useState(null)
  const t = themes[theme]
  const totaste = ['salt', 'pepper']

  const blucolor = b.state !== 2 ? t.blu0 : t.blu2
  const blufilter = b.state !== 2 ? t.blu0f : t.blu2f

  useEffect(() => {
    const db = getDatabase()
    onValue(refdb(db, 'elements/ingredients/' + b.ingredient), (s) => {
      setIcon(s.val())
    })
    // return () => refdb.off()
  }, [])

  const handlers = {
    onMouseUp: () => {
      let list = value.flat
      list.forEach((l) => {
        if (l.order == b.order) {
          l.state !== 2 ? (l.state = 2) : (l.state = 0)
        }
      })
      setValue((value) => ({
        ...value,
        flat: [...list],
      }))
    },
  }

  return (
    <>
      <motion.g
        {...handlers}
        animate={{
          x: b.state == 2 ? 0 : f.ingredient.dotSpace,
        }}
        transition={{ duration: s ? 0.06 : 0 }}
      >
        {icon ? (
          <svg
            className={'leaf-svg'}
            viewBox='0 0 128 128'
            fill={'none'}
            strokeWidth={1}
            stroke={blucolor}
            width={f.ingredient.elementSize}
            height={f.ingredient.elementSize}
            x={b.pX - f.ingredient.elementSize / 2}
            y={b.pY - f.ingredient.elementSize / 2}
            dangerouslySetInnerHTML={{ __html: icon }}
          />
        ) : (
          <circle
            className={'shape'}
            cx={b.pX}
            cy={b.pY}
            stroke={blucolor}
            fill={b.state == 2 ? blucolor : t.bak0}
            strokeWidth={f.ingredient.sw}
            r={f.ingredient.r}
          ></circle>
        )}
        <foreignObject
          className={'leaf'}
          width={f.ingredient.w}
          height={f.ingredient.boxHeight}
          x={b.pX - f.ingredient.elementSize + (!f.mobile ? 3 : 0)}
          y={b.pY - f.ingredient.boxHeight / 2}
        >
          <div className={'leaf-tap'}>
            <div
              className={'leaf-lab'}
              style={{
                color: blucolor,
                marginLeft: f.ingredient.boxNudge,
              }}
            >
              <div className={'leaf-name font3'}>{b.ingredient}</div>
              <div className={'leaf-more font1'}>
                {totaste.includes(b.ingredient)
                  ? 'to taste'
                  : clean(value.portion * b.ing_amt, b.ing_unit)}
              </div>
            </div>
          </div>
        </foreignObject>
      </motion.g>
    </>
  )
}

export default IngredientNode
