import { useEffect, useState, useMemo } from 'react'

import market from './market.mp4'

const Market = ({ s }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
      }}
    >
      <video controls height={s.height}>
        <source src={market} type='video/mp4' />
      </video>
    </div>
  )
}

export default Market
