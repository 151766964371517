import { useContext } from 'react'
import { Custom } from '../../context/custom'
import { Patron } from '../../context/patron'
import { Theme } from '../../context/theme'
import { themes } from '../../../media/theme/colors'

import Portion from '../4tools/portion'
import { rgb } from 'd3'

import './navigate.css'

import { Navigate } from 'react-router-dom'

const Header = ({ recipe }) => {
  const { value, setValue } = useContext(Custom)
  const { theme, setTheme } = useContext(Theme)

  const patron = useContext(Patron)

  const rgbB = rgb(themes[theme].bak0)

  const navItems = ['prelude', 'list', 'recipe']
  return (
    <div className={'recipe-nav'}>
      <div className={'recipe-nav-blur'}>
        <Portion serves={recipe.serves} />
        <div
          style={{ color: themes[theme].txt2 }}
          className={'recipe-nav-head font2'}
        >
          <h2>{recipe.name}</h2>
        </div>

        {/*<div className={'recipe-nav-nav'}>
              <nav>
                {navItems.map((i) => (
                  <button>
                    <div className='tick'></div>
                    <div className='label'>{i}</div>
                  </button>
                ))}
              </nav>
                </div>*/}
      </div>
    </div>
  )
}

export default Header
