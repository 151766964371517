const shapes = [
  <>
    <path d='M24.3,35A5.79,5.79,0,0,1,20,33.28l-.14-.17c0-.07-5.38-7.36-12.58-15.4a5.39,5.39,0,0,1-1.36-4A6,6,0,0,1,7.94,9.62C8.8,8.87,9.45,8.4,10.2,7.85a73.24,73.24,0,0,0,6.88-5.7A6.52,6.52,0,0,1,21.8.41a4.85,4.85,0,0,1,3.38,1.75c.15.2,9.33,12.56,12.68,16.2,1.88,2.05,1.71,5.13-.43,7.84s-3.28,3.83-9,7.61A7.59,7.59,0,0,1,24.3,35Z' />
    <path d='M39.31,36.87a1.36,1.36,0,0,0-1.52-1.48,1.48,1.48,0,1,0,0,3A1.43,1.43,0,0,0,39.31,36.87Z' />
    <path d='M44.24,49.88c.2-.85-.18-1.48-1.29-1.48a1.48,1.48,0,0,0,0,3C43.91,51.36,44.05,50.67,44.24,49.88Z' />
    <path d='M38.55,44.28a1.57,1.57,0,0,0-1.28-1.48,1.45,1.45,0,0,0-1.48,1.48,1.48,1.48,0,0,0,1.48,1.48C38.23,45.76,38.55,45.1,38.55,44.28Z' />
    <path d='M39.26,30.6c0-.81-.18-1.48-1.29-1.48a1.48,1.48,0,0,0,0,3C38.94,32.08,39.26,31.42,39.26,30.6Z' />
    <path d='M42,58a1.34,1.34,0,0,0-1.28-1.48A1.51,1.51,0,0,0,39.26,58a1.48,1.48,0,0,0,1.48,1.48C41.7,59.43,42,58.77,42,58Z' />
    <path d='M33.81,35.39c0-.82-.18-1.48-1.28-1.48a1.48,1.48,0,0,0,0,3C33.49,36.87,33.81,36.2,33.81,35.39Z' />
    <path d='M42.75,35.13a1.2,1.2,0,0,0-1,1.68c.2.79.68,1.35,1.47,1.16a1.48,1.48,0,0,0,1.08-1.8C44,35.24,43.55,34.93,42.75,35.13Z' />
  </>,
  <>
    <path d='M36,56.23a2,2,0,0,1-1.45-.63C34.42,55.46,20.68,41,12,33c-3.52-3.22-5.38-6.89-5.39-10.6S8.37,15.1,12,11.78A15.15,15.15,0,0,1,33,11.83l1,1a8.6,8.6,0,0,1,1.3-2.36,6,6,0,0,1,4.68-2,6.75,6.75,0,0,1,5.21,1.81c.36.35,2.63,2.59,3.43,3.43,4.95,5.22,5.11,7.87,4.8,11a9.35,9.35,0,0,0,.53,4.86L57,33.57a2,2,0,0,1-.31,2.79c-11.93,9.68-16,14.28-19,19a2,2,0,0,1-1.49.91ZM22.6,11.55a11.71,11.71,0,0,0-7.93,3.16c-2.73,2.53-4.11,5.12-4.1,7.68,0,3.25,2.23,6,4.09,7.66,7,6.39,17.09,16.84,21.09,21,3-4.16,7.34-8.71,16.9-16.57l-2-2.63a1.92,1.92,0,0,1-.29-.5,13.19,13.19,0,0,1-.89-7c.21-2.17.35-3.6-3.72-7.9-.8-.84-3.35-3.35-3.38-3.38L42.21,13a2.93,2.93,0,0,0-1.8-.51,3.21,3.21,0,0,0-2,.43c-.94,1.22-1.37,3-.17,4.21L42.15,21a2,2,0,0,1-2.83,2.83h0l-9.14-9.14A10.67,10.67,0,0,0,22.6,11.55Z' />
  </>,
  <>
    <path d='M21.09,14.33c.08,4.54,3.73,9.34,8.07,10.84.23,8.49.41,17.42-.29,29.29a3.54,3.54,0,0,0,7.06.42c.38-6.44.5-12,.5-17.12,0-4.59-.1-8.81-.21-12.92A10.65,10.65,0,0,0,43.7,14.33C43.62,9,38.63,2.66,32.4,3,26.58,3.35,21,7.51,21.09,14.33Z' />
  </>,
  <>
    <path d='M33.37,53.22h-.6a23.23,23.23,0,0,1-16.51-7.15,21.38,21.38,0,0,1-5.69-15.62C11.06,17.9,20.62,8.25,32.81,8a21.84,21.84,0,0,1,15.92,6.33A22.74,22.74,0,0,1,55.5,30.65h0A23.16,23.16,0,0,1,48.35,47,21.53,21.53,0,0,1,33.37,53.22Zm-.06-36.87H33c-7.69.17-13.74,6.37-14.06,14.42a13.13,13.13,0,0,0,3.45,9.59A14.93,14.93,0,0,0,33,44.85,13.15,13.15,0,0,0,42.56,41a14.86,14.86,0,0,0,4.59-10.42,14.38,14.38,0,0,0-4.3-10.3A13.27,13.27,0,0,0,33.31,16.35Z' />
  </>,
]
export { shapes }
