import { useState, useEffect, useContext, useMemo } from 'react'
import { getDatabase, onValue, ref as refdb, set } from 'firebase/database'
import { getStorage, ref as refs, getDownloadURL } from 'firebase/storage'
import { hierarchy } from 'd3'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import { motion } from 'framer-motion'

import { Custom } from '../context/custom'
import { themes } from '../../media/theme/colors'
import { Theme } from '../context/theme'

import parse from './_formats/parse'
import M from './_formats/formatMobile.json'
import D from './_formats/formatDesktop.json'

import Desktop from './desktop'
import Mobile from './mobile'
import './recipe.css'

import recs from './_.json'

const Recipe = ({ s, E }) => {
  const { theme } = useContext(Theme)
  const t = themes[theme]

  const [_recipe, set_recipe] = useState(null)
  const [value, setValue] = useState(null)
  const [splash, setSplash] = useState(1)

  var f = s ? D : M
  var r = _recipe ? parse(_recipe, f) : []
  const prov = useMemo(() => ({ value, setValue }), [value, setValue])

  let location = useLocation()
  let RID = location.pathname.split('/')[2]

  useEffect(() => {
    const db = getDatabase()
    onValue(refdb(db, 'recipes/' + RID + '/imgmain'), (s) => {
      setSplash(s.val())
    })
    onValue(refdb(db, 'recipes/' + RID), (s) => {
      // var rec = s.val()
      // var all = hierarchy(rec.recipe[0])
      var rec = recs[RID]
      var all = hierarchy(recs[RID].recipe[0])

      all.eachBefore((d) => {
        d.data.state = 0
        if (!d.data.vessel) {
          d.data.blur = d.parent.data.blur ? 1 : 0
          d.data.order = [d.parent.data.id, d.data.id]
        } else if (!!d.data.vessel) {
          d.data.order = [d.data.id]
        }
      })
      rec.ready = all
      rec.flat = []
      set_recipe(rec)
      setValue(rec)
    })
  }, [])
  return (
    <>
      <Custom.Provider value={prov}>
        <>
          {splash && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.15, ease: 'easeIn' }}
              className={'recipe-splash'}
            >
              <div className={'splash-mask'}>
                <motion.img
                  src={splash}
                  draggable={false}
                  initial={{ scale: 1.05 }}
                  animate={{ scale: 1 }}
                  transition={{ ease: 'easeOut', duration: 1 }}
                />
              </div>
            </motion.div>
          )}
        </>
        {_recipe && s && <Desktop r={r} f={f} t={t} E={E} />}
        {_recipe && !s && <Mobile r={r} f={f} t={t} E={E} />}
      </Custom.Provider>
    </>
  )
}

export default Recipe
