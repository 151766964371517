import { motion } from 'framer-motion'

const Logo = ({ t, fade, s, ss }) => {
  return (
    <>
      <motion.g
        {...fade}
        animate={{
          opacity: s ? 1 : 0.2,
          filter: s ? 'blur(0px)' : 'blur(4px)',
        }}
      >
        <g>
          <path
            style={{
              fill: t.grn1,
              transform: ss ? '' : 'scale(1) translate(-43px, -120px)',
            }}
            d='M62.71,807.7h-.55A21.31,21.31,0,0,1,47,801.13a19.7,19.7,0,0,1-5.23-14.35c.46-11.51,9.24-20.37,20.42-20.61A20.08,20.08,0,0,1,76.82,772,20.92,20.92,0,0,1,83,787h0a21.35,21.35,0,0,1-6.57,15A19.78,19.78,0,0,1,62.71,807.7Zm-.05-33.85h-.3c-7.07.15-12.62,5.84-12.92,13.24a12.06,12.06,0,0,0,3.17,8.8A13.69,13.69,0,0,0,62.38,800a12.11,12.11,0,0,0,8.77-3.56,13.58,13.58,0,0,0,4.21-9.57,13.18,13.18,0,0,0-3.94-9.45A12.22,12.22,0,0,0,62.66,773.85Z'
          />
          <path
            style={{
              fill: t.blu2,
              transform: ss ? '' : 'scale(1) translate(-43px, -120px)',
            }}
            d='M105.26,769a10.15,10.15,0,0,0-2.89-7.29,10.32,10.32,0,0,0-7.44-3.09,10.53,10.53,0,0,0-10.32,10.23,9.92,9.92,0,0,0,2.81,7.39,10.56,10.56,0,0,0,5.14,3c.16,5.93.07,9.92,0,13.75-.09,4-.18,7.78,0,13.46a2.4,2.4,0,0,0,2.4,2.32H95a2.4,2.4,0,0,0,2.32-2.47c-.18-5.55-.1-9.26,0-13.19s.19-7.9,0-13.89A10.78,10.78,0,0,0,105.26,769Zm-3.84-.1a6.76,6.76,0,0,1-6.49,6.77,6.56,6.56,0,0,1-4.73-2.08A6.12,6.12,0,0,1,88.44,769a6.79,6.79,0,0,1,6.49-6.58,6.47,6.47,0,0,1,4.69,1.93,6.28,6.28,0,0,1,1.8,4.51Z'
          />
        </g>
      </motion.g>
    </>
  )
}

export default Logo
