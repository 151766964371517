const themes = [
  {
    light: 0,
    opp: '#FFF',
    blk0: '#000',
    bak0: '#111111',
    bak1: '#171717',
    bak2: '#212121',
    bak3: '#191919',
    bak4: '#333333',
    blu: '#849BDC',
    blu0: '#A0B2E4',
    blu0f:
      'invert(74%) sepia(22%) saturate(654%) hue-rotate(191deg) brightness(93%) contrast(91%)',
    blu1: '#365ABF',
    blu1f:
      'invert(31%) sepia(17%) saturate(6526%) hue-rotate(210deg) brightness(88%) contrast(84%)',
    blu2: '#243C7F',
    blu2f:
      'invert(17%) sepia(50%) saturate(2767%) hue-rotate(212deg) brightness(89%) contrast(86%)',
    orn0: '#F1B090',
    orn0f:
      'invert(90%) sepia(64%) saturate(2126%) hue-rotate(297deg) brightness(100%) contrast(90%)',
    orn1: '#D6703D',
    orn1f:
      'invert(67%) sepia(38%) saturate(4890%) hue-rotate(334deg) brightness(89%) contrast(87%)',
    orn2: '#6C2400',
    orn2f:
      'invert(17%) sepia(38%) saturate(3511%) hue-rotate(6deg) brightness(94%) contrast(103%)',
    grn0: '#A1D39A',
    grn1: '#A1D39A',
    grn2: '#BCF0B5',
    red0: '#DF5F5F',
    gry1: '#BCBCBC',
    drk1: '#161616',
    drk2: '#111111',
    neo1: '#232323',
    neo2: '#0b0b0b',
    txt1: '#FFFFFF',
    txt2: '#DDDDDD',
    txt3: '#ADADAD',
    txt4: '#191919',
    txt5: '#191919',
    err0: '#F3C5C5',
    err1: '#4E0000',
    str1: 1,
  },
  {
    light: 1,
    opp: '#000',
    blk0: '#000',
    bak0: '#FAE4C9',
    bak1: '#FFECD6',
    bak2: '#F0EBDE',
    bak3: '#FFEEDB',
    bak4: '#FFF5EB',
    bak5: '#FFF5EB',
    blu0: '#092075',
    blu0f:
      'invert(13%) sepia(63%) saturate(3391%) hue-rotate(223deg) brightness(79%) contrast(107%)',
    blu1: '#8798D8',
    blu1f:
      'invert(68%) sepia(6%) saturate(2759%) hue-rotate(192deg) brightness(88%) contrast(91%)',
    blu2: '#818DBA',
    blu2f:
      'invert(74%) sepia(2%) saturate(6603%) hue-rotate(194deg) brightness(77%) contrast(88%)',
    orn0: '#DC5000',
    orn1: '#DC5B11',
    orn2: '#EB9D70',
    grn0: '#125E08',
    grn1: '#146D08',
    grn2: '#98D091',
    red0: '#B31F1F',
    gry1: '#999999',
    drk1: '#FFECD6',
    drk2: '#99774D',
    neo1: '#fffff7',
    neo2: '#c1b09b',
    txt1: '#5B544B',
    txt2: '#70685E',
    txt3: '#D1D1D1',
    txt4: '#E2DDCF',
    err0: '#FFD8D8',
    err1: '#800606',
    str1: 2.5,
  },
  {
    light: 2,
    bak0: '#F5F2E9',
    bak1: '#FFE4C4',
    bak2: '#F0EBDE',
    blu0: '#092075',
    blu1: '#8798D8',
    blu2: '#818DBA',
    orn0: '#DC5000',
    orn1: '#DC5B11',
    orn2: '#EB9D70',
    grn0: '#125E08',
    grn1: '#146D08',
    gry1: '#999999',
    txt1: '#444444',
    txt2: '#999999',
    txt3: '#D1D1D1',
    txt4: '#E2DDCF',
    str1: 3,
  },
]

export { themes }
