const restaurant_safe = {
  apiKey: "AIzaSyAoFYFJ9L5Wfod25-mB3OaJDNwOyn_h1D8",
  authDomain: "vizrecipes.firebaseapp.com",
  databaseURL: "https://vizrecipes-default-rtdb.firebaseio.com",
  projectId: "vizrecipes",
  storageBucket: "vizrecipes.appspot.com",
  messagingSenderId: "544945409785",
  appId: "1:544945409785:web:33b983b26cd5d3c2625c28"
};

export default restaurant_safe;
