import { useState, useEffect, useRef } from 'react'
import { timeFormat, timeParse } from 'd3-time-format'

import { rgb } from 'd3-color'

import Blu1 from './groups/blu1'
import Blu2 from './groups/blu2'
import Blu3 from './groups/blu3'
import Blu4 from './groups/blu4'
import Blu5 from './groups/blu5'

import Orn1 from './groups/orn1'
import Orn2 from './groups/orn2'
import Grn1 from './groups/grn1'
import Grn2 from './groups/grn2'
import Path from './groups/path'
import Logo from './groups/logo'

import { motion, useViewportScroll } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
const Learn = ({ t, s }) => {
  const [all, All] = useInView()
  const [all2, All2] = useInView()
  const [all3, All3] = useInView()

  const [blue, Blue] = useInView()
  const [orange, Orange] = useInView()
  const [green, Green] = useInView()
  const [m1, iM1] = useInView()
  const [m2, iM2] = useInView()

  const [ref1, Ref1] = useInView()
  const [ref2, Ref2] = useInView()
  const [ref3, Ref3] = useInView()
  const [ref4, Ref4] = useInView()
  const [ref5, Ref5] = useInView()
  const [ref6, Ref6] = useInView()
  const [ref7, Ref7] = useInView()
  const [ref8, Ref8] = useInView()

  const learn = [
    {
      name: 'ingredients',
      color: 'are blue',
      t: t.blu0,
      ref: blue,
    },
    {
      name: 'actions',
      color: 'are orange',
      t: t.orn1,
      ref: orange,
    },
    {
      name: 'vessels',
      color: 'are green',
      t: t.grn1,
      ref: green,
    },
  ]

  const steps = [
    {
      step: 'get a toaster',
      ref: ref1,
    },
    {
      step: 'put a slice of bread in it',
      ref: ref2,
    },
    {
      step: 'toast it',
      ref: ref3,
    },
    {
      step: 'meanwhile, get a mortar and pestle',
      ref: ref4,
    },
    {
      step: 'add avocado, oil, salt, pepper',
      ref: ref5,
    },
    {
      step: 'mash it',
      ref: ref6,
    },
    {
      step: '',
      ref: ref7,
    },
    {
      step: '',
      ref: ref8,
    },
  ]

  const fade = {
    initial: { opacity: 0, scale: 1 },
    exit: { opacity: 0 },
  }
  const rr = (c) => {
    const cc = rgb(c)
    // cc.opacity = 0.8
    return cc.darker([5])
  }
  const shadow = (c) => (s ? '' : `0px 0px 80px ${rr(c)}`)
  // const shadow = s ? '' : `5px 5px 40px #000`

  const h = useRef()
  return (
    <motion.div
      className='learn'
      style={{ height: h.current && h.current.offsetHeight }}
    >
      <div className='learn-example'>
        <motion.svg
          className='example'
          viewBox={s ? '0 0 671.46 830.56' : '0 0 407 800'}
          width={s ? '60%' : 'calc(100vw - 10px)'}
        >
          <g transform={s ? '' : 'translate( 15, 60 )'}>
            <Path t={t} fade={fade} s={All || All2 || Ref7} a={Ref7} ss={s} />
            <Grn1 t={t} fade={fade} s={All || All2 || Green || Ref1} ss={s} />
            <Grn2 t={t} fade={fade} s={All || All2 || Green || Ref4} ss={s} />
            <Orn1
              t={t}
              fade={fade}
              s={All || All2 || Orange || Ref3}
              a={Ref3}
              ss={s}
            />
            <Orn2
              t={t}
              fade={fade}
              s={All || All2 || Orange || Ref6}
              a={Ref6}
              ss={s}
            />
            <Blu1
              t={t}
              fade={fade}
              s={All || All2 || Blue || Ref5}
              a={Ref5}
              ss={s}
            />
            <Blu2
              t={t}
              fade={fade}
              s={All || All2 || Blue || Ref5}
              a={Ref5}
              ss={s}
            />
            <Blu3
              t={t}
              fade={fade}
              s={All || All2 || Blue || Ref5}
              a={Ref5}
              ss={s}
            />
            <Blu4
              t={t}
              fade={fade}
              s={All || All2 || Blue || Ref5}
              a={Ref5}
              ss={s}
            />
            <Blu5
              t={t}
              fade={fade}
              s={All || All2 || Blue || Ref2}
              a={Ref2}
              ss={s}
            />
            <Logo t={t} fade={fade} s={All || All2 || Ref8} ss={s} />
          </g>
        </motion.svg>
      </div>

      <motion.div className='learn-aside' ref={h}>
        <motion.div
          className={'learn-ref'}
          style={{ width: 1, height: '90vh', top: 0 }}
          ref={all}
        ></motion.div>

        <motion.div className={'learn-item'}>
          <motion.div
            className={'learn-title font3'}
            style={{ textShadow: shadow(t.opp), color: t.opp }}
          >
            an example <br />
            <div>basic avocado toast</div>
          </motion.div>
        </motion.div>
        {learn.map((d, i) => (
          <motion.div className={'learn-item'}>
            <motion.div
              className={'learn-title font3'}
              style={{ color: d.t, textShadow: shadow(d.t) }}
            >
              {d.name}
              <br />
              <div>{d.color}</div>
            </motion.div>
            <motion.div className={'learn-ref'} ref={d.ref}></motion.div>
          </motion.div>
        ))}

        <motion.div className={'learn-steps'}>
          <motion.div className={'learn-steps-refs'}>
            {steps.map((d, i) => (
              <motion.div
                className={'learn-step-ref'}
                ref={d.ref}
                style={{
                  opacity: (i + 1) * 0.1,
                  x: i * 3,
                  height: `calc(${50 * (steps.length - i)}vh - 20vh)`,
                }}
              ></motion.div>
            ))}
          </motion.div>
          <motion.div className={'learn-steps-list'}>
            {steps.map((d, i) => (
              <motion.div
                className={'learn-step font3'}
                style={{ textShadow: shadow, opacity: 0 }}
              >
                {i + 1 + '. ' + d.step}
              </motion.div>
            ))}
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.div>
  )
}

export default Learn
