import { useState, useEffect, useContext } from 'react'
import { getDatabase, onValue, ref as refdb } from 'firebase/database'
import { scaleOrdinal } from 'd3'
import { elements, shapes } from './intro/shapes'
import _ from 'lodash'
// import { ReactComponent as Learn2 } from './learn/learn2.svg'

import { motion } from 'framer-motion'
import Box from './intro/box'
const Welcome = ({ t, E }) => {
  const letters = 'viz'.split('')
  const v = {
    m: 1,
    p: 300,
    d: 14,
    a: 0.01,
    de: 0.05,
  }

  return (
    <>
      {letters.map((d, i) => (
        <Box choice={[E, d]} i={i} t={t} v={v} E={E} />
      ))}
    </>
  )
}

export default Welcome
