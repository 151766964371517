import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { useState, useEffect, useRef } from 'react'
import { timeFormat, timeParse } from 'd3-time-format'
import { format } from 'd3-format'

const Orn1 = ({ t, fade, s, a, ss }) => {
  const [start, setStart] = useState()
  const [scro, setScro] = useState()

  const { scrollYProgress } = useViewportScroll()
  const ti = useTransform(scrollYProgress, [start + 0.02, start + 0.22], [1, 0])
  useEffect(() => {
    return scrollYProgress.onChange((l) => {
      setScro(l)
    })
  }, [])
  useEffect(() => {
    setStart(a && scrollYProgress.current)
  }, [a])

  return (
    <>
      <motion.g
        {...fade}
        animate={{
          x: a ? (ss ? -60 : -33) : 0,
          opacity: s ? 1 : 0.2,
          filter: s ? 'blur(0px)' : 'blur(4px)',
        }}
      >
        <g
          fill={t.orn1}
          style={{
            transform: ss ? '' : 'scale(.75) translate(-52px, -40px)',
          }}
        >
          <g>
            <g>
              <path d='M160,223.43h1.27v2h3.34v1.21h-3.34v2.46c0,.27,0,.47,0,.58a2.32,2.32,0,0,0,.06.46.93.93,0,0,0,.17.41,1.05,1.05,0,0,0,.34.22,1.34,1.34,0,0,0,.56.1,3.58,3.58,0,0,0,.85-.1,5.8,5.8,0,0,0,.71-.21l.84-.34.47-.19.49,1.16a8.32,8.32,0,0,1-3.24.94,2.57,2.57,0,0,1-1.92-.66,2,2,0,0,1-.5-1,6.9,6.9,0,0,1-.11-1.4v-2.46h-1v-1.21h1Z' />
              <path d='M166.67,225.34h3.21v1.2h-.79l1.78,3.43,2-3.43h-.76v-1.2h3.15v1.2h-.93l-3.67,6.4h1.09v1.2h-4.83v-1.2h2.27l1-1.65-2.49-4.75h-1Z' />
              <path d='M175.4,225.34h2.43v.55a3.31,3.31,0,0,1,2.19-.76,3.21,3.21,0,0,1,2.46,1,3.46,3.46,0,0,1,1,2.41,3.33,3.33,0,0,1-1,2.5,3.38,3.38,0,0,1-2.37,1,3.31,3.31,0,0,1-2.2-.81V233h2.11v1.2H175.4V233h1.16v-6.46H175.4Zm2.37,3.21a2.08,2.08,0,0,0,.71,1.65,2.27,2.27,0,0,0,1.52.62,2,2,0,0,0,1.48-.65,2.22,2.22,0,0,0,.62-1.59,2.35,2.35,0,0,0-.55-1.53,1.86,1.86,0,0,0-1.53-.66,2.21,2.21,0,0,0-1.61.64A2.09,2.09,0,0,0,177.77,228.55Z' />
              <path d='M185.73,225.34h3.37v5.43h2.44V232h-6.21v-1.2h2.49v-4.23h-2.09Zm1.81-1.28v-1.93h1.56v1.93Z' />
              <path d='M199.48,225.13l1.15.12a13.13,13.13,0,0,0-.09,1.47,9.51,9.51,0,0,0,.09,1.28l-1.23.17c-.05-.31-.1-.53-.14-.69A2.1,2.1,0,0,0,199,227a1,1,0,0,0-.56-.44,3.32,3.32,0,0,0-1-.13,2.86,2.86,0,0,0-1.26.26,1.78,1.78,0,0,0-.75.64,2.72,2.72,0,0,0-.33.73,2.3,2.3,0,0,0,.48,2.2,2.14,2.14,0,0,0,1.75.7,3,3,0,0,0,1.13-.24,6.69,6.69,0,0,0,1-.46c.26-.16.57-.37.95-.63l.7,1a6.46,6.46,0,0,1-3.83,1.6,3.56,3.56,0,0,1-2.6-1,3.43,3.43,0,0,1-1-2.51,3.6,3.6,0,0,1,1-2.46,3.37,3.37,0,0,1,2.61-1.07,3.14,3.14,0,0,1,2.08.81Z' />
              <path d='M208.58,227.48v3.29h1.26V232h-2.53v-.7a4.11,4.11,0,0,1-2.42.91,2.53,2.53,0,0,1-1.84-.7,2.15,2.15,0,0,1-.73-1.59,1.93,1.93,0,0,1,.17-.75,2.48,2.48,0,0,1,.51-.78,2.43,2.43,0,0,1,1-.63,4.6,4.6,0,0,1,1.6-.25c.41,0,1,0,1.67.11a2.39,2.39,0,0,0,0-.43,1.66,1.66,0,0,0-.15-.39.75.75,0,0,0-.43-.36,2.39,2.39,0,0,0-.83-.12,6.73,6.73,0,0,0-2.56.67l-.5-1.17a8.45,8.45,0,0,1,3.07-.76,2.81,2.81,0,0,1,2,.64A2.36,2.36,0,0,1,208.58,227.48Zm-1.27,2.12v-.76a12.71,12.71,0,0,0-1.53-.16,3.73,3.73,0,0,0-1,.12,2.06,2.06,0,0,0-.64.26,1.18,1.18,0,0,0-.33.34,1.14,1.14,0,0,0-.13.31.75.75,0,0,0,0,.2.9.9,0,0,0,.38.7,1.27,1.27,0,0,0,.85.31A3.37,3.37,0,0,0,207.31,229.6Z' />
              <path d='M211.76,222.73l3.68-.25v8.29h2.46V232h-6.2v-1.2h2.46v-7l-2.28.15Z' />
              <path d='M220.55,222.73l3.67-.25v8.29h2.47V232h-6.2v-1.2H223v-7l-2.29.15Z' />
              <path d='M228.19,225.34h3.21v1.2h-.79L232.4,230l2-3.43h-.76v-1.2h3.15v1.2h-.92l-3.68,6.4h1.1v1.2h-4.84v-1.2h2.27l1-1.65-2.49-4.75h-1Z' />
              <path d='M252,229.34h1.23V232h-6.1v-1.2q2.25-1.89,4-3.66a2.28,2.28,0,0,0,.76-1.63,1.53,1.53,0,0,0-.45-1.12,1.64,1.64,0,0,0-1.22-.46,2.62,2.62,0,0,0-.61.08,1.27,1.27,0,0,0-.62.35.88.88,0,0,0-.31.7,1.55,1.55,0,0,0,0,.31h-1.32v-1.28a3.61,3.61,0,0,1,2.87-1.45,2.92,2.92,0,0,1,2.1.85,2.91,2.91,0,0,1,.56,3.35,4.44,4.44,0,0,1-.8,1.12c-.33.35-.87.87-1.62,1.55l-1.42,1.29H252Z' />
              <path d='M264.23,225.34h2.09v.48a2.25,2.25,0,0,1,1.41-.69,1.45,1.45,0,0,1,1.2.69l.4-.32a2.62,2.62,0,0,1,.48-.24,2,2,0,0,1,.67-.13c1.24,0,1.86.91,1.86,2.71v2.93H273V232h-1.95v-4.13c0-.27,0-.47,0-.6a4.07,4.07,0,0,0-.07-.42.49.49,0,0,0-.19-.33.6.6,0,0,0-.37-.1.87.87,0,0,0-.47.15,1.52,1.52,0,0,0-.3.24l-.32.37v3.62h.87V232h-2.14v-4.19c0-.19,0-.34,0-.46s0-.25-.06-.42a.72.72,0,0,0-.19-.38.53.53,0,0,0-.38-.13,1.82,1.82,0,0,0-1.08.63v3.75h1V232h-3.12v-1.2h.83v-4.23h-.83Z' />
              <path d='M274.7,225.34h3.37v5.43h2.45V232h-6.21v-1.2h2.49v-4.23h-2.1Zm1.82-1.28v-1.93h1.55v1.93Z' />
              <path d='M282.14,225.34h2.47V226a3.84,3.84,0,0,1,2.12-.82,2.23,2.23,0,0,1,1.74.74,2.71,2.71,0,0,1,.66,1.9v3h1.19V232h-3.69v-1.2h1.23v-2.94a1.72,1.72,0,0,0-.3-1,.93.93,0,0,0-.8-.41,2,2,0,0,0-.82.19,4.35,4.35,0,0,0-.65.32l-.68.49v3.38h1.11V232h-3.61v-1.2h1.22v-4.23h-1.19Z' />
              <path d='M158.47,243.47h7.2v1.29h-7.2Zm0,2.56h7.2v1.29h-7.2Z' />
              <path d='M177.14,240.73l3.68-.25v8.29h2.46V250h-6.19v-1.2h2.46v-7l-2.29.15Z' />
              <path d='M186.27,243.34h3.37v5.43h2.45V250h-6.22v-1.2h2.49v-4.23h-2.09Zm1.82-1.28v-1.93h1.55v1.93Z' />
              <path d='M199.2,243.34h2.44v1.2h-1.16v4.77a2.69,2.69,0,0,1-.83,2.28,3.93,3.93,0,0,1-2.38.61h-1.52V251a11.61,11.61,0,0,0,2.65-.16,1.42,1.42,0,0,0,.64-.5,1,1,0,0,0,.11-.37,3.64,3.64,0,0,0,0-.38v-.45a3.05,3.05,0,0,1-2,.72,3.29,3.29,0,0,1-3.3-3.28,3.14,3.14,0,0,1,1-2.35,3.25,3.25,0,0,1,2.32-.95,3.09,3.09,0,0,1,2,.69Zm0,3.25a2,2,0,0,0-.57-1.47,1.88,1.88,0,0,0-1.37-.58,1.94,1.94,0,0,0-1.4.58,1.87,1.87,0,0,0-.61,1.44,1.92,1.92,0,0,0,.59,1.44,1.88,1.88,0,0,0,1.4.6,1.84,1.84,0,0,0,1.41-.6A2,2,0,0,0,199.2,246.59Z' />
              <path d='M202.34,240.69l2.69-.21V244a3.84,3.84,0,0,1,2.13-.82,2.23,2.23,0,0,1,1.74.74,2.69,2.69,0,0,1,.66,1.89v3h1.19V250h-3.69v-1.2h1.23v-3a1.82,1.82,0,0,0-.28-1,.94.94,0,0,0-.82-.41,4,4,0,0,0-2.16,1v3.38h1.12V250h-3.61v-1.2h1.22v-6.94l-1.32.09Z' />
              <path d='M213.28,241.43h1.28v2h3.34v1.21h-3.34v3a3.72,3.72,0,0,0,.07.46.83.83,0,0,0,.17.41,1,1,0,0,0,.33.22,1.39,1.39,0,0,0,.57.1,3.64,3.64,0,0,0,.85-.1,5.8,5.8,0,0,0,.71-.21l.84-.34.47-.19.48,1.16a8.24,8.24,0,0,1-3.23.94,2.57,2.57,0,0,1-1.92-.66,1.91,1.91,0,0,1-.51-1,7.71,7.71,0,0,1-.11-1.4v-2.46h-1v-1.21h1Z' />
              <path d='M228.47,240.63l2.49-.15v3.61a3.32,3.32,0,0,1,2.16-.81,3.37,3.37,0,0,1,2.45,1,3.29,3.29,0,0,1,1,2.43,3.36,3.36,0,0,1-1,2.37,3.21,3.21,0,0,1-2.44,1,3.46,3.46,0,0,1-2.21-.79V250h-2.4v-1.2h1.13v-7l-1.13.06Zm2.49,6.06a2.13,2.13,0,0,0,.64,1.53,2.09,2.09,0,0,0,1.56.64,1.93,1.93,0,0,0,1.49-.63,2.16,2.16,0,0,0,.59-1.52,2.1,2.1,0,0,0-.61-1.53,2,2,0,0,0-1.48-.64,2.08,2.08,0,0,0-1.55.64A2.11,2.11,0,0,0,231,246.69Z' />
              <path d='M238.3,243.34h2.9v1.08a9,9,0,0,1,.7-.61,3.43,3.43,0,0,1,.83-.45,2.88,2.88,0,0,1,1.12-.23,2.81,2.81,0,0,1,1.57.5c.12.1.26.23.43.4l-1.31,1a1.06,1.06,0,0,0-.94-.63,1,1,0,0,0-.37.07,2.2,2.2,0,0,0-.39.17c-.12.07-.25.15-.39.25a3.57,3.57,0,0,0-.35.28q-.16.14-.33.3a3.37,3.37,0,0,0-.27.27l-.2.2-.1.1v2.74h2.71V250h-5.74v-1.2h1.76v-4.23H238.3Z' />
              <path d='M246.65,246.6a3.49,3.49,0,0,1,1-2.54,3.74,3.74,0,0,1,2.77-1,3.65,3.65,0,0,1,2.77,1.06,3.48,3.48,0,0,1,1,2.51,3.54,3.54,0,0,1-1,2.51,3.62,3.62,0,0,1-2.79,1.07,3.66,3.66,0,0,1-2.79-1.06A3.52,3.52,0,0,1,246.65,246.6Zm1.35,0a2.31,2.31,0,0,0,.65,1.64,2.39,2.39,0,0,0,1.8.68,2.28,2.28,0,0,0,2.44-2.32,2.29,2.29,0,0,0-.64-1.62,2.31,2.31,0,0,0-1.8-.69,2.38,2.38,0,0,0-1.81.67A2.29,2.29,0,0,0,248,246.6Z' />
              <path d='M254.84,243.34H258v1.2h-1.09l.81,3.1h0l1.14-3.1h.89l1.17,3.1h0l.82-3.1h-1.09v-1.2h3v1.2H263L261.53,250h-1.15l-1.13-3.19h0l-1.1,3.19H257l-1.5-5.43h-.67Z' />
              <path d='M264,243.34h2.46V244a3.87,3.87,0,0,1,2.12-.82,2.23,2.23,0,0,1,1.74.74,2.71,2.71,0,0,1,.66,1.9v3h1.19V250h-3.69v-1.2h1.23v-2.94a1.79,1.79,0,0,0-.29-1,1,1,0,0,0-.81-.41,2,2,0,0,0-.82.19,3.72,3.72,0,0,0-.64.32c-.14.09-.37.25-.69.49v3.38h1.11V250h-3.6v-1.2h1.21v-4.23H264Z' />
            </g>
            <g>
              <path d='M165.92,196.09V198a.09.09,0,0,1-.08.08h-.42a1.72,1.72,0,0,1-.31,0h-2.34v13.17a.07.07,0,0,1-.08.08h-2.18a.08.08,0,0,1-.09-.08.25.25,0,0,0,0-.11c0-.16,0-.44,0-.62,0-.52,0-1.66,0-3,0-2.17,0-4.88,0-5.86s0-2.09,0-3.15v-.39h-.8l-.64,0-1.15,0-.2,0-.14,0h-.28a.09.09,0,0,1-.08-.08v-1.91a.09.09,0,0,1,.08-.09h4.61c1,0,1.95,0,2.93,0h1.11A.09.09,0,0,1,165.92,196.09Z' />
              <path d='M175.22,204.42v.48c0,.41,0,.9,0,1.39a6.13,6.13,0,0,1-1.12,4,3.65,3.65,0,0,1-2.65,1.26h-.31a4.13,4.13,0,0,1-2.9-1.08,4.34,4.34,0,0,1-1.19-3c0-.4,0-.82-.06-1.22v0c-.12-1.85-.1-3.71-.07-5.11a6.19,6.19,0,0,1,1-4,3.67,3.67,0,0,1,2.66-1.26h.31a4.19,4.19,0,0,1,2.9,1.07,4.35,4.35,0,0,1,1.25,3c0,.4,0,.82.06,1.22s.06,1,.07,1.49c0,.28,0,.56,0,.85v1Zm-2.14-.07v-.8h0c0-.29,0-.54,0-.76a14.39,14.39,0,0,0-.26-3.17,2.12,2.12,0,0,0-1-1.46,1.71,1.71,0,0,0-.86-.21,1.92,1.92,0,0,0-1,.3,3,3,0,0,0-1,2.55c0,.9,0,1.78,0,2.75h0v.59c0,.18,0,.34,0,.46a14.45,14.45,0,0,0,.26,3.17,2.09,2.09,0,0,0,1,1.46,1.77,1.77,0,0,0,.86.21,1.89,1.89,0,0,0,1-.3,3,3,0,0,0,1-2.55C173.1,205.92,173.09,205.09,173.08,204.35Z' />
              <path d='M186,211.3a.21.21,0,0,1-.17.08h-1.85a.19.19,0,0,1-.2-.16l-.09-.39h0c0-.09,0-.2-.07-.29v-.05l0-.19c-.25-1.27-.4-2-.44-2.12s0-.14-.05-.19l-.05-.14-.15,0h-.19c-.2,0-1.11,0-1.83,0-.33,0-.51,0-.54,0-.61,0-.74.06-.82.11s-.1.15-.14.36l-.09.41c-.1.43-.2.9-.29,1.35-.05.26-.11.51-.16.76l-.08.4a.2.2,0,0,1-.2.17h-1.87a.21.21,0,0,1-.17-.08.23.23,0,0,1,0-.18l.11-.4h0l.06-.28c0-.09.05-.19.08-.31v-.07c.23-.9.44-1.82.65-2.72.1-.45.21-.93.32-1.4.18-.73.35-1.47.53-2.21v0l.71-3c.12-.5.23-1,.35-1.52s.23-1,.35-1.54l.07-.3h0l.22-1a.44.44,0,0,1,.43-.35H182a.45.45,0,0,1,.43.32l.05.19v0c.05.12.09.28.14.45v0c.38,1.43,1.8,7.41,2.56,10.62.22.93.38,1.6.41,1.75l.06.22c.08.32.16.65.23,1l.15.55A.23.23,0,0,1,186,211.3Zm-3.53-5.55,0-.28q0-.25-.09-.54c0-.08-.08-.48-.16-1-.21-1.26-.54-3.36-.68-3.9l-.25-1-.22,1c0,.15-.14.78-.27,1.57-.16,1-.37,2.31-.45,2.69,0,.09-.06.19-.09.29a2.07,2.07,0,0,0-.1.33c0,.18-.09.38-.13.57l-.06.29h.32a2.19,2.19,0,0,0,.36,0l.28,0h1.58Z' />
              <path d='M195.46,208.27a3.86,3.86,0,0,1-2.53,3,5.08,5.08,0,0,1-1.55.26h-.12a4.2,4.2,0,0,1-3.06-1.12,4.44,4.44,0,0,1-1.29-2.84,2.35,2.35,0,0,1,0-.26c0-.17,0-.35,0-.55s0-.28,0-.41a.15.15,0,0,1,.14-.13h1.76a.13.13,0,0,1,.14.13l.05.58a3.37,3.37,0,0,0,.37,1.51,2,2,0,0,0,1.66,1,1.89,1.89,0,0,0,1.73-.64,2.71,2.71,0,0,0-.4-3.72,6.66,6.66,0,0,0-1.72-.93,11.4,11.4,0,0,1-1-.49,4.85,4.85,0,0,1-2.07-2,4.36,4.36,0,0,1-.14-3.47,3.48,3.48,0,0,1,2.43-2.12,4.54,4.54,0,0,1,3.92.75,4.9,4.9,0,0,1,1.64,3.36.47.47,0,0,1,0,.12v0l0,.23s0,0,0,0l0,0h-2c-.08-2.24-1.59-2.47-2.09-2.55h-.08a1.52,1.52,0,0,0-1.54.47,1.74,1.74,0,0,0-.4,1.88,3.29,3.29,0,0,0,2,1.66l.28.12.1,0a7.4,7.4,0,0,1,2.38,1.36h0A5.2,5.2,0,0,1,195.46,208.27Z' />
              <path d='M205.12,196.09V198a.08.08,0,0,1-.08.08h-.42a1.62,1.62,0,0,1-.31,0H202v13.17a.08.08,0,0,1-.08.08h-2.19a.08.08,0,0,1-.08-.08v-.11c0-.16,0-.44,0-.62v-3c0-2.17,0-4.88,0-5.86s0-2.09,0-3.15v-.39h-.81l-.64,0-1.15,0-.2,0-.14,0h-.27a.08.08,0,0,1-.08-.08v-1.91a.08.08,0,0,1,.08-.09H201c1,0,1.95,0,2.93,0H205A.08.08,0,0,1,205.12,196.09Z' />
            </g>
          </g>
          <g
            style={{
              stroke: 'none',
              fill: t.orn1,
            }}
          >
            <path
              style={{
                fill: 'none',
                stroke: t.orn1,
                strokeWidth: 2,
              }}
              class='h'
              d={
                'M129.89,221V479.57a7,7,0,0,1-7,7.05h0a7.05,7.05,0,0,1-7-7.05V221'
              }
            />
            <g>
              <text
                x={'124'}
                y={'212'}
                style={{
                  textAnchor: 'middle',
                  fontSize: '18px',
                  fill: t.orn1,
                  fontFamily: 'Open Sans',
                }}
              >
                {a
                  ? 120000 - Math.round((scro - start) * 500000) > 120000
                    ? '2:00'
                    : 120000 - Math.round((scro - start) * 500000) < 0
                    ? '00:00'
                    : timeFormat('%M:%S')(
                        120000 - Math.round((scro - start) * 500000)
                      )
                  : '2:00'}
              </text>
            </g>
            {a && (
              <>
                {120000 - Math.round((scro - start) * 500000) > 0 && (
                  <motion.path d='M129.89,479.91a6.88,6.88,0,0,1-7,6.71h0a6.89,6.89,0,0,1-7-6.71' />
                )}
                <motion.path
                  style={{ scaleY: ti, originX: 0.5, originY: 1 }}
                  d='M129.89,221V481H116V221'
                />
                )
              </>
            )}
          </g>
        </g>
      </motion.g>
    </>
  )
}

export default Orn1
