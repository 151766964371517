import { useEffect, useState, useMemo } from 'react'
import { useWindowSize } from './main/recipe/4tools/windowsize'

import { useLocation } from 'react-router'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Routes, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'
import { auth } from './main/pantry/pantry'
import { Patron } from './main/context/patron'
import { Palette } from './main/context/palette'
import { Theme } from './main/context/theme'
import Kitchen from './main/kitchen/kitchen/kitchen'
import Recipe from './main/recipe/recipe'
import Menu from './main/kitchen/menu/menu'
import Market from './main/market/market'

const App = () => {
  const location = useLocation()
  const size = useWindowSize()
  const s = size.width > 800

  const [palette, setPalette] = useState(0)
  const [reveal, setReveal] = useState(0)
  const [user, loading, error] = useAuthState(auth)
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') ? +localStorage.getItem('theme') : 1
  )

  const themeValue = useMemo(() => ({ theme, setTheme }), [theme, setTheme])
  const paletteValue = useMemo(
    () => ({ palette, setPalette }),
    [palette, setPalette]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const E = [
    'almond',
    'avocado',
    // 'celery',
    // 'cubanelle',
    // 'cucumber',
    // 'eggplant',
    // 'lemon juice',
    // 'yogurt',
    // 'zucchini',
    'banana',
    'basil',
    'bay leaf',
    'bell pepper',
    'bread',
    'broccoli',
    'butter',
    'carrot',
    'cashew',
    'chicken',
    'cilantro',
    'cinnamon',
    'corn',
    'dill',
    'egg',
    'flour',
    'garlic',
    'grape',
    'honey',
    'lamb chop',
    'lemon',
    'milk',
    'mushroom',
    'mustard powder',
    'neutral oil',
    'olive oil',
    'onion',
    'parsley',
    'pepper',
    'pineapple',
    'potato',
    'rice',
    'rosemary',
    'salt',
    'scallion',
    'soy sauce',
    'sweet potato',
    'thyme',
    'tomato',
    'vinegar',
    'water',
    'wine',
  ]

  return (
    <>
      <Theme.Provider value={themeValue}>
        <Patron.Provider value={user}>
          <Palette.Provider value={paletteValue}>
            <AnimatePresence>
              <Routes location={location} key={location.pathname}>
                <Route
                  path='/'
                  element={
                    <Kitchen reveal={reveal} setReveal={setReveal} s={s} />
                  }
                >
                  <Route index element={<Menu s={s} E={E} />} />
                  <Route path='/r/:id' element={<Recipe s={s} E={E} />} />
                  <Route path='/m' element={<Market s={size} />} />
                </Route>
              </Routes>
            </AnimatePresence>
          </Palette.Provider>
        </Patron.Provider>
      </Theme.Provider>
    </>
  )
}

export default App
