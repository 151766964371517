import { useState, useEffect, useRef, useContext } from 'react'
import { motion } from 'framer-motion'
import { timeFormat, timeParse } from 'd3-time-format'
import { useTimer } from 'use-timer'

import { Theme } from '../../../context/theme'
import { Custom } from '../../../context/custom'
import { themes } from '../../../../media/theme/colors'
import { useWindowSize } from '../../4tools/windowsize'

const Action = ({ f, b }) => {
  const { theme } = useContext(Theme)
  const t = themes[theme]
  const { value, setValue } = useContext(Custom)
  const [hover, setHover] = useState(0)
  const size = useWindowSize()

  const orncolor = b.state !== 2 ? t.orn0 : t.orn2
  const aHeight = b.action_amt > 5 ? b.action_amt * f.action.amtScale : 0

  const { time, start, pause, status } = useTimer()

  const handlers = {
    onMouseUp: () => {
      let list = value.flat
      list.forEach((l) => {
        if (l.order == b.order) {
          l.state !== 2 ? (l.state = 2) : (l.state = 0)
        }
      })
      setValue((value) => ({
        ...value,
        flat: [...list],
      }))
      if (aHeight) {
        status == 'RUNNING' ? pause() : start()
      }
    },
  }

  useEffect(() => {
    if (b.action_amt * 60 == time) {
      pause()
    }
  }, [time])

  return (
    <motion.g
      {...handlers}
      animate={{
        x: b.state == 2 ? 0 : f.ingredient.dotSpace,
      }}
      transition={{ duration: 0.05 }}
    >
      <rect
        x={b.pX - f.ingredient.r}
        y={
          aHeight
            ? b.pY + f.action.amtScale * (time / 60)
            : b.pY - f.ingredient.r
        }
        height={
          aHeight
            ? aHeight - f.action.amtScale * (time / 60)
            : f.ingredient.r * 2
        }
        width={f.ingredient.r * 2}
        fill={
          aHeight ? t.bak0 : b.state == 2 ? (hover ? t.orn1 : orncolor) : t.bak0
        }
        stroke={hover ? t.orn1 : orncolor}
        strokeWidth={f.ingredient.sw}
        rx={f.ingredient.r}
      ></rect>

      <foreignObject
        className={'leaf'}
        width={f.ingredient.w}
        height={f.ingredient.boxHeight}
        x={b.pX - f.ingredient.elementSize + (!f.mobile ? 3 : 0)}
        y={b.pY - f.ingredient.boxHeight / 2}
      >
        <div className={'leaf-tap'}>
          <div
            className={'leaf-lab'}
            style={{
              color: orncolor,
              marginLeft: f.ingredient.boxNudge,
            }}
          >
            <div className={'leaf-name font3'}>{b.action}</div>
            <div className={'leaf-more font1'}>{b.detail}</div>
          </div>
        </div>
      </foreignObject>
      {aHeight ? (
        <g>
          <rect
            style={{
              cursor: 'pointer',
            }}
            x={b.pX - f.action.width / 2}
            y={b.pY}
            height={f.action.width + f.action.amtScale * (time / 60) - 2}
            width={f.action.width}
            fill={t.orn1}
            rx={f.action.width / 2}
          ></rect>
          <rect
            className={'timer-countbackground'}
            x={b.pX - f.vessel.s.width / 2 + 2}
            y={b.pY + f.action.amtScale * (time / 60) - 10}
            fill={t.bak0}
            strokeWidth={0}
            stroke={'none'}
            width={f.vessel.s.width - 4}
            height={24}
            rx={8}
          ></rect>
          <text
            style={{
              cursor: 'pointer',
            }}
            className={'timer-countdown'}
            x={b.pX + 0.5}
            y={b.pY + f.action.amtScale * (time / 60) + 6}
            fill={t.orn1}
          >
            {timeFormat('%M:%S')(b.action_amt * 60000 - time * 1000)}
          </text>
        </g>
      ) : undefined}
    </motion.g>
  )
}

export default Action
