import Plate from '../plate/plate'
import { motion } from 'framer-motion'

const Section = ({ d }) => {
  return (
    <motion.section className='menu-section'>
      <motion.div className='menu-section-main'>
        {d.dishes.map((d, i) => (
          <Plate d={d} i={i} />
        ))}
      </motion.div>
    </motion.section>
  )
}

export default Section
