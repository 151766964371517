import { useEffect, useState } from 'react'

import { motion } from 'framer-motion'

const NavWrap = ({ d, i, spring }) => {
  const [label, setLabel] = useState(0)

  return (
    <>
      <motion.div
        className='head-wrap'
        onMouseEnter={() => setLabel(1)}
        onMouseLeave={() => setLabel(0)}
        // initial={{ x: -56, opacity: 0 }}
        // animate={{ x: 0, opacity: 1 }}
        // transition={{
        //   delay: 0.1 + i * 0.1,
        //   duration: 0.4,
        //   type: 'spring',
        // }}
      >
        {/* <motion.div
          {...spring}
          className='nav-label'
          animate={{ x: label ? 54 : 0, opacity: label }}
        >
          {d.label}
        </motion.div> */}
        <motion.div
          {...spring}
          className='nav-item'
          animate={{ x: label ? 4 : 0 }}
        >
          {d.item}
        </motion.div>
      </motion.div>
    </>
  )
}

export default NavWrap
