import { useState, useEffect, useContext } from 'react'
import { getDatabase, onValue, ref as refdb } from 'firebase/database'
import { scaleOrdinal } from 'd3'
import { elements, shapes } from './intro/shapes'
import _ from 'lodash'
import Box from './intro/box'
import { useTimer } from 'use-timer'
const Welcome = ({ t, E }) => {
  const letters = 'a visual recipe proposal'.split('')

  const v = {
    m: 0,
    p: 750,
    d: 34,
    a: 0.12,
    de: 0.028,
  }

  return (
    <>
      {letters.map((d, i) => {
        return (
          <>
            <Box choice={[E, d]} i={i} t={t} v={v} E={E} />
          </>
        )
      })}
    </>
  )
}

export default Welcome
