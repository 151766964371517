import { useState, useEffect, useContext } from 'react'
import { linkVertical } from 'd3'

import { getDatabase, onValue, ref as refdb } from 'firebase/database'
import _ from 'lodash'

import { Custom } from '../../../context/custom'
import { Theme } from '../../../context/theme'
import { themes } from '../../../../media/theme/colors'

const Vessel = ({ d, i, f }) => {
  const { theme } = useContext(Theme)
  const { value, setValue } = useContext(Custom)
  const [el, setEl] = useState(null)

  useEffect(() => {
    const db = getDatabase()
    onValue(refdb(db, 'elements/vessels/' + d.vessel), (s) => {
      setEl(s.val())
    })
    // return () => refdb.off()
  }, [])

  const element = (
    <svg
      className='ingredient-element'
      viewBox='0 0 128 128'
      fill={themes[theme].grn1}
      width={f.vessel.elementSize}
      height={f.vessel.elementSize}
      x={d.pX - f.vessel.elementSize * 0.5}
      y={d.pY - f.vessel.elementSize * 0.5}
      dangerouslySetInnerHTML={{ __html: el }}
    ></svg>
  )

  const shape1 = (
    <path
      d={`M ${d.pX} ${d.pY + f.vessel.s.width} V ${
        d.pH + d.pY - f.vessel.s.width / 4
      }`}
      className={'shape'}
      fill={'none'}
      stroke={themes[theme].grn2}
      strokeWidth={3}
      strokeLinecap={'round'}
    ></path>
  )

  const shape2 = (
    <path
      d={`M ${d.pX - f.vessel.s.width / 2} ${d.pY + f.vessel.s.width} L ${
        d.pX - f.vessel.s.width / 2
      } ${d.pH + d.pY - f.vessel.s.width / 4} A ${f.vessel.s.width / 2} ${
        f.vessel.s.width / 2
      } 0 0 0 ${d.pX + f.vessel.s.width / 2} ${
        d.pH + d.pY - f.vessel.s.width / 4
      } L ${d.pX + f.vessel.s.width / 2} ${d.pY + f.vessel.s.width}`}
      className={'shape'}
      fill={'none'}
      stroke={themes[theme].grn1}
      strokeWidth={themes[theme].str1}
      strokeLinecap={'round'}
    ></path>
  )

  const label = (
    <text
      fill={themes[theme].grn1}
      className={'title font3'}
      x={d.pX - 4}
      y={d.pY + 5}
      alignmentBaseline={'hanging'}
    >
      {d.vessel.replace('and', '&')}
    </text>
  )

  const transfer = (
    <path
      className={'transfer'}
      id={'path' + i}
      stroke={themes[theme].grn2}
      fill={'none'}
      d={linkVertical()({
        source: [d.pX, d.pY + d.pH + (f.mobile ? 0 : 12)],
        target: [
          d.parentPx,
          d.parentPy - f.ingredient.elementSize * 0.5 - (f.mobile ? 0 : 12),
        ],
      })}
    ></path>
  )

  const transferText = (
    <text
      className={'transfer-text font3'}
      fill={themes[theme].grn2}
      textAnchor={'middle'}
    >
      <textPath href={'#path' + i} startOffset={'45%'}>
        {d.transfer}
      </textPath>
    </text>
  )

  return (
    <>
      <g
        className={'vessel'}
        style={{ cursor: 'pointer' }}
        // filter={f.mobile ? (d.blur ? 'url(#blur)' : null) : null}
      >
        {transfer}
        {transferText}
        {f.mobile ? shape1 : shape2}
        {label}
        {element}
        {/* {!!f.mobile ? (
          d.blur ? (
            <rect
              x={d.pX - f.vessel.s.width / 2}
              y={d.pY - f.vessel.s.width / 2}
              width={220}
              fill={'transparent'}
              height={d.pH + f.vessel.s.width}
              onClick={() => {
                let list = value.flat
                list.forEach((l) => {
                  if (l.order[0] == d.order[0]) {
                    l.blur = 0
                  }
                  if (!!l.parallel && !!d.parallel) {
                    if (l.parallel[0] == d.parallel[0]) {
                      if (l.parallel[1] !== d.parallel[1]) {
                        l.blur = 1
                      }
                    }
                  }
                })
                setValue((value) => ({
                  ...value,
                  flat: [...list],
                }))
              }}
            ></rect>
          ) : undefined
        ) : undefined} */}
      </g>
    </>
  )
}

export default Vessel
