import { motion } from 'framer-motion'

const Foot = ({ footRef, t }) => {
  return (
    <footer
      ref={footRef}
      style={{
        color: t.txt1,
      }}
    >
      <motion.div
        className={'copyright font1'}
        initial={{ x: -220 }}
        whileInView={{ x: 0 }}
        transition={{ duration: 0.3, delay: 0.2, type: 'spring' }}
      >
        <svg height={18} viewBox='0 0 64 64'>
          <circle
            fill={'none'}
            stroke={t.txt1}
            strokeWidth={6}
            cx={32}
            cy={32}
            r={24}
          />
          <text x={16} y={45} fill={t.txt1} fontSize={52}>
            c
          </text>
        </svg>
        <div>viz.recipes</div>
      </motion.div>
      <motion.div
        className={'source font1'}
        whileHover={{ x: 222, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        by elijah munn
      </motion.div>
    </footer>
  )
}

export default Foot
