import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { useState, useEffect, useRef } from 'react'

const Logo = ({ t, fade, s, ss }) => {
  return (
    <>
      <motion.g
        {...fade}
        animate={{
          opacity: s ? 1 : 0.2,
          filter: s ? 'blur(0px)' : 'blur(4px)',
        }}
        stroke={t.grn2}
        style={{
          fill: 'none',
        }}
      >
        <motion.g>
          <motion.path
            d={ss ? 'M62.71,513V764.03' : 'M20,415V640'}
            strokeDasharray={ss ? 'none' : '0 14'}
            strokeLinecap={ss ? 'square' : 'round'}
            strokeWidth={ss ? 8 : 5}
          />
          <motion.path
            strokeWidth={ss ? 8 : 5}
            strokeLinecap={ss ? 'square' : 'round'}
            strokeDasharray={ss ? 'none' : '0 14'}
            d={
              ss
                ? 'M462,513c-16.1,110.3-388.27,131-399,242.64'
                : 'M225,415C225,510,20,510,20,640'
            }
          />
        </motion.g>
      </motion.g>
    </>
  )
}

export default Logo
