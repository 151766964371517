import { useRef, useState, useEffect, useContext } from 'react'
import { parse, clean } from '../../pantry/tools'
import format from '../_formats/formatDesktop.json'
import { motion } from 'framer-motion'
import { Custom } from '../../context/custom'
import { getDatabase, onValue, ref as refdb } from 'firebase/database'

const ListItem = ({ d, t, f }) => {
  const { value, setValue } = useContext(Custom)
  const [hover, setHover] = useState(0)
  const [icon, setIcon] = useState(null)

  const blu = d.state == 2 ? t.blu2 : t.blu0

  useEffect(() => {
    const db = getDatabase()
    onValue(refdb(db, 'elements/ingredients/' + d.ingredient), (s) => {
      setIcon(s.val())
    })

    // return () => refdb.off()
  }, [])
  const hove = {
    onMouseEnter: () => {
      setHover(1)
    },
    onMouseLeave: () => {
      setHover(0)
    },
  }
  const handlers = {
    ...(!f.mobile ? hove : undefined),
    onMouseUp: () => {
      let { flat } = value
      flat.forEach((l) => {
        if (l.order == d.order) {
          l.state = !l.state
        }
      })
      setValue((value) => ({
        ...value,
        flat: [...flat],
      }))
    },
  }

  return (
    <>
      <motion.div
        {...handlers}
        className={'inglist-item'}
        onMouseEnter={() => setHover(1)}
        onMouseLeave={() => setHover(0)}
        animate={{ color: hover ? t.blu1 : blu }}
      >
        <motion.div className={'inglist-flex'}>
          <motion.svg
            viewBox='0 0 128 128'
            fill={'none'}
            animate={{
              stroke: hover ? t.blu1 : blu,
            }}
            strokeWidth={1}
            dangerouslySetInnerHTML={{ __html: icon }}
          ></motion.svg>
          <motion.div className={'inglist-text'}>
            <motion.div
              className={'inglist-title font3'}
              animate={{ letterSpacing: hover ? '0.5px' : 0 }}
            >
              {d.ingredient}
            </motion.div>
            <div className={'inglist-amt font1'}>
              {clean(value.portion * d.ing_amt, d.ing_unit)}
            </div>
          </motion.div>
        </motion.div>

        <motion.div
          className={'inglist-cross'}
          animate={{
            background: hover ? t.blu1 : blu,
            width: d.state ? '100%' : 0,
          }}
        ></motion.div>
      </motion.div>
    </>
  )
}

export default ListItem
