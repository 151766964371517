import { motion } from 'framer-motion'

const Blu3 = ({ t, fade, s, a, ss }) => {
  return (
    <>
      <motion.g
        {...fade}
        animate={{
          x: a ? (ss ? -60 : -24) : 0,
          opacity: s ? 1 : 0.2,
          filter: s ? 'blur(0px)' : 'blur(4px)',
        }}
        transition={{
          x: {
            delay: 0.4,
          },
        }}
        fill={t.blu0}
      >
        <g
          style={{
            transform: ss ? '' : 'scale(.75) translate(-190px, 0px)',
          }}
        >
          <g>
            <path d='M559.72,340.28H561v2.05h3.34v1.2H561v3.05a4,4,0,0,0,.07.46.88.88,0,0,0,.17.4,1,1,0,0,0,.34.22,1.31,1.31,0,0,0,.56.1,3.64,3.64,0,0,0,.85-.1,5.8,5.8,0,0,0,.71-.21c.18-.06.46-.18.84-.33l.47-.19.48,1.15a8.24,8.24,0,0,1-3.23.94,2.57,2.57,0,0,1-1.92-.66,2,2,0,0,1-.51-1,7.63,7.63,0,0,1-.11-1.4v-2.46h-1v-1.2h1Z' />
            <path d='M566.78,345.45a3.45,3.45,0,0,1,1-2.54,4.17,4.17,0,0,1,5.53,0,3.62,3.62,0,0,1,0,5,3.59,3.59,0,0,1-2.79,1.07,3.71,3.71,0,0,1-2.79-1A3.55,3.55,0,0,1,566.78,345.45Zm1.35,0a2.29,2.29,0,0,0,.65,1.63,2.72,2.72,0,0,0,3.61,0,2.31,2.31,0,0,0,.63-1.62,2.27,2.27,0,0,0-2.44-2.32,2.4,2.4,0,0,0-1.81.68A2.31,2.31,0,0,0,568.13,345.45Z' />
            <path d='M586.63,340.28h1.28v2.05h3.34v1.2h-3.34v3.05a4,4,0,0,0,.07.46.79.79,0,0,0,.17.4,1,1,0,0,0,.33.22,1.39,1.39,0,0,0,.57.1,3.64,3.64,0,0,0,.85-.1,5.8,5.8,0,0,0,.71-.21c.18-.06.46-.18.84-.33l.47-.19.48,1.15a8.24,8.24,0,0,1-3.23.94,2.57,2.57,0,0,1-1.92-.66,1.87,1.87,0,0,1-.51-1,7.63,7.63,0,0,1-.11-1.4v-2.46h-1v-1.2h1Z' />
            <path d='M600.05,344.32v3.3h1.26v1.2h-2.54v-.71a4.08,4.08,0,0,1-2.41.91,2.56,2.56,0,0,1-1.84-.69,2.19,2.19,0,0,1-.73-1.59A1.94,1.94,0,0,1,594,346a2.45,2.45,0,0,1,.51-.77,2.54,2.54,0,0,1,1-.64,4.6,4.6,0,0,1,1.6-.25,15.42,15.42,0,0,1,1.66.12,2.5,2.5,0,0,0,0-.43,1.56,1.56,0,0,0-.15-.4.75.75,0,0,0-.43-.36,2.39,2.39,0,0,0-.83-.12,6.78,6.78,0,0,0-2.56.68l-.5-1.17a8.5,8.5,0,0,1,3.07-.77,2.86,2.86,0,0,1,2,.64A2.38,2.38,0,0,1,600.05,344.32Zm-1.28,2.12v-.76a14.93,14.93,0,0,0-1.52-.16,3.73,3.73,0,0,0-1,.12,2.34,2.34,0,0,0-.64.26,1.1,1.1,0,0,0-.33.35,1.78,1.78,0,0,0-.14.3,1.68,1.68,0,0,0,0,.2.88.88,0,0,0,.38.7,1.27,1.27,0,0,0,.85.31A3.33,3.33,0,0,0,598.77,346.44Z' />
            <path d='M608,341.84l1.15.19a6.55,6.55,0,0,0-.15,1.28,4.27,4.27,0,0,0,.15,1.05l-1.15.17a1,1,0,0,1-.07-.2l-.12-.33c-.17-.51-.77-.76-1.78-.76s-1.54.27-1.54.82a.63.63,0,0,0,.15.43.87.87,0,0,0,.49.25,3.34,3.34,0,0,0,.53.11l.64.06,1.15.08a4.32,4.32,0,0,1,.7.14,1.7,1.7,0,0,1,1.35,1.7,1.92,1.92,0,0,1-.82,1.56,3.36,3.36,0,0,1-2.1.63,3.44,3.44,0,0,1-1.58-.39l-.47-.35-.17.76-1.15-.08a8.7,8.7,0,0,0,.15-1.39,4.83,4.83,0,0,0-.15-1.14l1.15-.13a1.84,1.84,0,0,0,.58,1.07,2.1,2.1,0,0,0,1.41.39,2.9,2.9,0,0,0,1.32-.25.79.79,0,0,0,.51-.71.49.49,0,0,0-.37-.52,6.9,6.9,0,0,0-1.4-.17,5.39,5.39,0,0,1-2.27-.49,1.9,1.9,0,0,1-.7-.68,1.73,1.73,0,0,1-.27-.93,1.7,1.7,0,0,1,.86-1.51,3.44,3.44,0,0,1,1.84-.52,3.18,3.18,0,0,1,1.94.68Z' />
            <path d='M613,340.28h1.27v2.05h3.34v1.2h-3.34V346c0,.27,0,.47,0,.59a4,4,0,0,0,.07.46.89.89,0,0,0,.16.4,1.17,1.17,0,0,0,.34.22,1.35,1.35,0,0,0,.57.1,3.71,3.71,0,0,0,.85-.1,6.39,6.39,0,0,0,.71-.21c.18-.06.46-.18.84-.33l.47-.19.48,1.15a8.32,8.32,0,0,1-3.24.94,2.57,2.57,0,0,1-1.92-.66,1.93,1.93,0,0,1-.5-1A7.63,7.63,0,0,1,613,346v-2.46h-1v-1.2h1Z' />
            <path d='M627.31,346.71l.59,1.13a7.7,7.7,0,0,1-3.8,1.18,3.65,3.65,0,0,1-2.75-1,3.36,3.36,0,0,1-1-2.47A3.51,3.51,0,0,1,624,342a3.41,3.41,0,0,1,2.56,1,3,3,0,0,1,.93,2.14,8.12,8.12,0,0,1,0,.9H621.7a2.89,2.89,0,0,0,.17.52,2.64,2.64,0,0,0,.34.54,1.48,1.48,0,0,0,.72.53,3,3,0,0,0,1.14.19A6.52,6.52,0,0,0,627.31,346.71Zm-5.52-1.94h4.35a2,2,0,0,0-2.13-1.53A2.17,2.17,0,0,0,621.79,344.77Z' />
          </g>
          <g>
            <path d='M567.3,325.18a3.84,3.84,0,0,1-2.53,3,4.84,4.84,0,0,1-1.55.27h-.13a4.21,4.21,0,0,1-3.06-1.12,4.51,4.51,0,0,1-1.29-2.84c0-.09,0-.18,0-.27s0-.34,0-.54,0-.28,0-.42a.14.14,0,0,1,.14-.12h1.77a.15.15,0,0,1,.14.13l0,.58a3.24,3.24,0,0,0,.37,1.51,2,2,0,0,0,1.65,1,1.94,1.94,0,0,0,1.74-.64,2.72,2.72,0,0,0-.41-3.73,6.88,6.88,0,0,0-1.71-.92,9.45,9.45,0,0,1-1.06-.5,4.76,4.76,0,0,1-2.06-2,4.33,4.33,0,0,1-.15-3.46,3.49,3.49,0,0,1,2.44-2.13,4.55,4.55,0,0,1,3.92.76,4.89,4.89,0,0,1,1.63,3.36.49.49,0,0,1,0,.12v0l0,.23s0,0,0,0l0,0h-2c-.09-2.24-1.6-2.48-2.1-2.55l-.08,0a1.55,1.55,0,0,0-1.53.47,1.74,1.74,0,0,0-.4,1.89,3.27,3.27,0,0,0,2,1.66l.28.12.11,0a7.41,7.41,0,0,1,2.37,1.35h0A5.22,5.22,0,0,1,567.3,325.18Z' />
            <path d='M577.92,328.21a.23.23,0,0,1-.17.08H575.9a.22.22,0,0,1-.21-.16l-.08-.39h0l-.06-.3v0l0-.19c-.26-1.27-.4-2-.44-2.12a1.08,1.08,0,0,0-.06-.19l0-.14-.15,0-.19,0-1.83,0h-.53c-.62,0-.75,0-.82.11s-.11.15-.15.36l-.09.41c-.1.43-.19.89-.29,1.34,0,.26-.1.52-.16.76l-.07.41a.21.21,0,0,1-.21.17h-1.87a.21.21,0,0,1-.17-.08.19.19,0,0,1,0-.18l.11-.41h0l.06-.28c0-.09.05-.19.08-.31l0-.07c.23-.9.44-1.82.64-2.72l.33-1.4c.17-.74.35-1.48.52-2.21v0c.22-.92.47-2,.7-3,.12-.51.24-1,.35-1.52s.24-1,.35-1.54l.07-.3h0l.21-1a.45.45,0,0,1,.43-.35h1.65a.45.45,0,0,1,.42.31l.06.2h0c.05.13.09.28.14.46v0c.38,1.42,1.79,7.4,2.55,10.62.22.92.38,1.59.42,1.74l.05.23c.08.32.16.64.23,1l.15.55A.19.19,0,0,1,577.92,328.21Zm-3.53-5.56,0-.27c0-.17-.06-.35-.1-.55s-.08-.47-.16-1c-.2-1.26-.54-3.36-.67-3.9l-.26-1-.22,1c0,.16-.13.78-.26,1.58-.17,1-.38,2.31-.46,2.69a2.33,2.33,0,0,1-.09.29c0,.11-.07.22-.09.32s-.09.38-.13.58l-.06.28h.32l.36,0,.27,0h1.59Z' />
            <path d='M586.66,327.07c0,.18,0,.36,0,.51,0,.64-.1.69-.1.69h-7.2a3.23,3.23,0,0,1,0-.7c0-.08,0-.16,0-.23,0-.86,0-1.74,0-2.59v-.56c0-.52,0-1.06,0-1.59,0-.21,0-.92,0-1.76,0-2.25,0-5.65,0-6.78,0-.05,0-.67.08-1.05v0a.1.1,0,0,1,.1-.1h.68c.12,0,.23,0,.33,0h1a.1.1,0,0,1,.1.1V326.1h3.56c.59,0,1,0,1.39,0A2.64,2.64,0,0,1,586.66,327.07Z' />
            <path d='M593.77,313v1.92a.09.09,0,0,1-.08.08h-3.07V328.2a.08.08,0,0,1-.08.08h-2.19a.08.08,0,0,1-.08-.08.59.59,0,0,1,0-.11c0-.17,0-.44,0-.62,0-.52,0-1.67,0-3,0-2.18,0-4.88,0-5.86s0-2.09,0-3.16v-.39H586.8l-1.15,0a.58.58,0,0,1-.19,0H585a.08.08,0,0,1-.08-.08V313a.08.08,0,0,1,.08-.08h.78c.19,0,.41,0,.64,0h3.19c1,0,2,0,2.93,0h1.11A.09.09,0,0,1,593.77,313Z' />
          </g>
          <g>
            <path d='M534.83,317.63l-.17,0-.1,0a.81.81,0,0,0-.22.07,2.51,2.51,0,0,0-.48.32,3.49,3.49,0,0,0-.49.45,4,4,0,0,0-.58.87c-.06.14-.13.27-.19.41l-.19.42c-.06.15-.13.29-.19.44s-.11.27-.17.4-.11.31-.16.47l-.12.39c-.09.31-.17.61-.25.91a8.41,8.41,0,0,0-.19.87c-.06.31-.09.63-.13.95s-.06.6-.08.91,0,.59,0,.89a6.19,6.19,0,0,0,0,.86,3.73,3.73,0,0,0,.19.88,2.32,2.32,0,0,0,.16.44,2.69,2.69,0,0,0,.19.38c0,.06.07.11.1.16l.06.09a1,1,0,0,0,.08.11c0,.05.09.08.13.12a.72.72,0,0,0,.24.1,1.51,1.51,0,0,0,.3,0,2.64,2.64,0,0,0,.6-.15c.17-.07.34-.16.51-.25a3.21,3.21,0,0,0,.52-.39,3.48,3.48,0,0,0,.68-1q.23-.51.42-1c.06-.14.12-.28.17-.43s.1-.26.14-.39l.08-.29a1,1,0,0,0,0-.16l.12-.53c.06-.25.12-.51.16-.76s.08-.38.11-.58,0-.26.07-.39c0-.32.09-.63.13-.95s.07-.64.1-1,0-.56,0-.84,0-.5,0-.75a4.23,4.23,0,0,0-.11-.62,3.07,3.07,0,0,0-.23-.64,2.16,2.16,0,0,0-.39-.59l-.24-.22a1,1,0,0,0-.33-.15h-.06a.27.27,0,0,0-.22.12m.09.55a.25.25,0,0,0,.18-.08h0l.11.08a1.86,1.86,0,0,1,.27.29,3.29,3.29,0,0,1,.23.46,4.36,4.36,0,0,1,.2.81c0,.28,0,.56,0,.84s0,.38,0,.57,0,.35,0,.53,0,.31-.06.47-.07.57-.12.86,0,.3-.07.45-.07.36-.1.54-.1.53-.16.79-.09.36-.13.53-.08.28-.12.41,0,.18-.09.27-.08.24-.13.36-.15.39-.23.58a6.94,6.94,0,0,1-.59,1.19,2,2,0,0,1-.31.34,3.12,3.12,0,0,1-.62.4,2.81,2.81,0,0,1-.56.18h-.2l-.09,0,0,0,0-.05a4.52,4.52,0,0,1-.36-.69,4.59,4.59,0,0,1-.24-.9,5.34,5.34,0,0,1,0-.69c0-.25,0-.51,0-.77,0-.53.09-1.06.14-1.59,0-.24.07-.48.12-.71s.13-.55.2-.83.13-.47.2-.71l.12-.38c0-.13.08-.26.13-.39s.1-.26.16-.39l.24-.54a7.52,7.52,0,0,1,.61-1.2,3.2,3.2,0,0,1,.43-.47l.22-.19a2.49,2.49,0,0,1,.38-.25l.22-.05.15,0m-.33.05h0m-.67.48,0,0,0,0' />
            <path d='M533.3,316.83a.29.29,0,0,0-.15.12c-.14.22-.28.43-.41.65a6.94,6.94,0,0,0-.38.71,8.92,8.92,0,0,0-.56,1.6c0,.2-.09.41-.14.62s-.09.44-.12.66v.08a.32.32,0,0,0,0,.14.27.27,0,0,0,.15.13.24.24,0,0,0,.2,0,.26.26,0,0,0,.11-.17,7.32,7.32,0,0,1,.15-.79c0-.17.07-.33.11-.5s.09-.35.14-.52l.18-.51a7.76,7.76,0,0,1,.44-.92c.08-.15.17-.29.26-.43l.28-.44a.3.3,0,0,0,0-.21.24.24,0,0,0-.11-.16.22.22,0,0,0-.13,0h-.07' />
            <path d='M531.18,316.7c-.09.13-.19.26-.28.41l-.23.39-.2.35-.21.43-.07.18a1.86,1.86,0,0,0-.1.25c0,.07-.05.15-.08.22s0,.14-.07.2l-.24.91-.24.87c-.09.3-.15.61-.22.91s-.11.53-.16.8-.06.35-.08.52l-.06.54c0,.13,0,.26,0,.39l0,.41c0,.34,0,.68,0,1,0,.57,0,1.15,0,1.72,0,.39.05.78.08,1.17a.27.27,0,0,0,.08.19.23.23,0,0,0,.17.08.23.23,0,0,0,.18-.08.26.26,0,0,0,.08-.19c0-.31,0-.62-.07-.93s0-.57,0-.86,0-.31,0-.47v-.53c0-.28,0-.57,0-.86s0-.58,0-.87.05-.45.08-.67,0-.33.08-.49,0-.28.08-.41c.05-.28.11-.55.18-.83s.15-.59.23-.88l.24-.87a2.5,2.5,0,0,1,.07-.26c0-.06,0-.12,0-.18s.09-.26.13-.4a7.71,7.71,0,0,1,.39-.82c.16-.28.31-.56.49-.83l.1-.14a.32.32,0,0,0,.07-.19.32.32,0,0,0-.07-.2.25.25,0,0,0-.18-.08.22.22,0,0,0-.18.08' />
            <path d='M530.17,316.21c-.13.13-.26.26-.38.4l-.24.35-.25.41c-.15.25-.29.51-.44.77s-.13.22-.18.33l-.22.43c-.05.12-.1.24-.14.35s-.13.32-.19.48-.21.54-.29.82-.09.31-.13.47-.07.31-.1.47-.1.56-.14.83-.05.33-.06.5l0,.45c0,.31,0,.61,0,.92s0,.6,0,.91a8.63,8.63,0,0,0,0,.88l0,.37c0,.12,0,.23.08.35s.06.28.1.42a4.83,4.83,0,0,0,.16.5.25.25,0,0,0,.15.12.27.27,0,0,0,.2,0,.28.28,0,0,0,.11-.17.3.3,0,0,0,0-.21v0a3.94,3.94,0,0,1-.14-.49c0-.2-.11-.41-.14-.61s0-.6,0-.89,0-.63,0-.94,0-.61,0-.91l0-.43c0-.13,0-.26,0-.39a13.82,13.82,0,0,1,.26-1.43c0-.13.06-.26.1-.38l.12-.38c.07-.23.16-.45.24-.67s.16-.42.26-.62.23-.46.35-.69l.39-.69c.13-.22.26-.44.4-.65a3.11,3.11,0,0,1,.23-.28,3.07,3.07,0,0,1,.25-.26.3.3,0,0,0,0-.39.25.25,0,0,0-.18-.08.27.27,0,0,0-.18.08' />
            <path d='M530,316.16a.28.28,0,0,0,.25.28,8.47,8.47,0,0,1,1.13.08,10.85,10.85,0,0,1,1.52.36c.24.08.47.17.7.27l.66.25.3.17.37.22a.25.25,0,0,0,.35-.1.31.31,0,0,0-.09-.38l-.35-.21-.31-.17c-.24-.12-.5-.2-.75-.3l-.36-.15-.37-.13c-.25-.08-.5-.15-.76-.21l-.48-.1-.54-.09a8.74,8.74,0,0,0-1-.07h0a.28.28,0,0,0-.25.28' />
            <path d='M527.22,327.58a.27.27,0,0,0-.11.16.33.33,0,0,0,0,.22l.12.15.11.09.12.09.26.16.35.17.35.14a9.77,9.77,0,0,0,1.35.46l.54.14.59.12,1.24.21h-.06c.12,0,.25,0,.37.06a.28.28,0,0,0,.2,0,.34.34,0,0,0,.11-.17.3.3,0,0,0,0-.21.24.24,0,0,0-.15-.13l-.55-.08-.79-.14-.77-.15a9.18,9.18,0,0,1-1.36-.39l-.49-.19-.47-.19-.3-.15-.27-.19-.05-.06a.3.3,0,0,0-.14-.11h-.07a.22.22,0,0,0-.13,0m4.79,1.53h0' />
            <path d='M526.94,321.18c0,.2-.07.4-.11.6a5.86,5.86,0,0,0-.1.58c0,.12,0,.25-.05.37v0a7.71,7.71,0,0,0,0,1,8,8,0,0,0,0,.85c0,.14,0,.29,0,.43s0,.33,0,.5,0,.34,0,.52,0,.32.07.49.08.41.13.62.07.21.1.32a1.55,1.55,0,0,0,.08.23,2.76,2.76,0,0,0,.2.47.24.24,0,0,0,.34.1.29.29,0,0,0,.09-.38c0-.11-.1-.21-.14-.32s-.1-.29-.14-.44a1.78,1.78,0,0,1-.09-.33c0-.12-.06-.24-.08-.36s0-.25-.05-.38,0-.49-.06-.73,0-.61-.05-.92,0-.49,0-.74v-.38c0-.08,0-.17,0-.26a1,1,0,0,1,0-.17h0c0-.11,0-.23,0-.35l.21-1.12a.31.31,0,0,0,0-.21.27.27,0,0,0-.15-.13h-.06a.26.26,0,0,0-.25.21m.29,1.2h0v0h0v-.05m0,0h0m-.54.35v0m.05-.4h0m0,0v0' />
            <path d='M530.54,315.6a3.4,3.4,0,0,1-.3.23,3.55,3.55,0,0,0-.31.23c-.12.09-.23.19-.34.29a3.71,3.71,0,0,0-.29.27c-.1.11-.2.22-.29.34s-.17.23-.25.34-.33.49-.48.74l-.15.23c-.06.1-.11.18-.16.28l-.15.29-.14.33a3.05,3.05,0,0,0-.13.32l-.12.32-.21.58,0-.07c0,.12-.09.24-.14.35l0-.06a6.5,6.5,0,0,0-.22.62,1.86,1.86,0,0,0-.07.52c0,.11,0,.23,0,.34a.25.25,0,1,0,.5,0,4.34,4.34,0,0,1,0-.51,4.25,4.25,0,0,1,.22-.75l0,.07.13-.35c.1-.25.19-.51.28-.76a8.53,8.53,0,0,1,.48-1.07c.21-.34.43-.68.65-1a6.9,6.9,0,0,1,.47-.6,5.38,5.38,0,0,1,.55-.52l.33-.25.34-.25a.29.29,0,0,0,.11-.16.33.33,0,0,0,0-.22.27.27,0,0,0-.15-.13h-.07a.2.2,0,0,0-.13,0m-2.86,5v0m0-.06v0Z' />
            <path d='M512.88,306.41a2.61,2.61,0,0,1-.55.14l-.29.07a1.65,1.65,0,0,0-.42.21l-.55.38a3.53,3.53,0,0,1-.5.28c-.22.1-.45.18-.67.26l-.36.15a.84.84,0,0,0-.21.11,1,1,0,0,0-.19.22l-.19.32c-.07.12-.14.24-.2.36s-.28.54-.41.81-.27.54-.42.8-.29.52-.44.78c-.28.5-.52,1-.76,1.54l-.27.57c-.1.2-.18.41-.26.61h0a3,3,0,0,0-.13.36c0,.12-.06.24-.08.37,0,.3-.07.6-.09.91s0,.58-.06.88,0,.65,0,1,0,.49,0,.73,0,.52,0,.79,0,.55,0,.82l.06,1.13a.27.27,0,0,0,.25.27.26.26,0,0,0,.26-.27c0-.39,0-.78-.07-1.17,0-.16,0-.32,0-.48s0-.26,0-.39c0-.34,0-.67,0-1,0-.14,0-.29,0-.43s0-.28,0-.42c0-.37,0-.73.06-1.09s.05-.65.08-1v.07c0-.14,0-.29.07-.44a3.24,3.24,0,0,1,.13-.41h0l.05-.12a3.79,3.79,0,0,1,.18-.42c.05-.12.11-.24.17-.37l.39-.82c.25-.55.56-1.07.85-1.59s.45-.85.66-1.27c.11-.21.21-.42.33-.63s.11-.2.17-.3l.14-.22,0,0,0,0c.41-.18.83-.31,1.24-.51a4.88,4.88,0,0,0,.58-.34l.32-.22.27-.18.28-.07.24-.06.23-.05.31-.11a.3.3,0,0,0,.12-.17.37.37,0,0,0,0-.21.27.27,0,0,0-.15-.13H513a.27.27,0,0,0-.12,0m-6.24,7.87,0,0,0,0' />
            <path d='M513.3,306.93a.26.26,0,1,0,.26-.28.27.27,0,0,0-.26.28' />
            <path d='M513,306.49a.29.29,0,0,0,.09.38,14.48,14.48,0,0,0,1.51.92c.48.27,1,.54,1.47.78l.84.4.29.15.26.13.14.06c.22.13.46.26.68.4l.74.42.57.32.59.31.65.31.58.27-.06,0c.49.23,1,.46,1.46.74l.75.44.45.27.35.19a3.07,3.07,0,0,0,.31.17l.46.23.71.33.76.37.43.22.36.19,1.41.75.78.41c.23.12.47.24.71.34l.09,0a.21.21,0,0,0,.19,0,.25.25,0,0,0,.12-.16.33.33,0,0,0,0-.22.32.32,0,0,0-.15-.13l-.25-.11c-.5-.24-1-.5-1.48-.77-.23-.12-.46-.23-.68-.36l-.78-.42-.41-.2-.35-.17q-.35-.18-.69-.33l-.84-.4-.74-.41-.69-.41-.41-.24-.31-.18-.74-.38-.86-.4-.28-.13c-.23-.11-.46-.21-.68-.33l-.47-.25-.33-.18-1.54-.88c-.23-.14-.48-.25-.72-.37l-.68-.32-1.5-.78c-.59-.33-1.18-.66-1.74-1.05a.24.24,0,0,0-.12,0,.25.25,0,0,0-.22.13m17.2,8.84,0,0,0,0' />
            <path d='M510.88,308.64a.3.3,0,0,0,0,.34,2.43,2.43,0,0,0,.55.42l.5.33.69.42.38.23.23.13.9.49.74.39.6.33c.51.31,1,.61,1.54.9l.53.3.23.13.9.44,1.55.78.62.35.67.38.9.51.23.11.53.27c.35.17.71.33,1.06.48s.87.41,1.3.63l.42.21.34.19c.23.13.45.27.67.41a.25.25,0,0,0,.35-.1.3.3,0,0,0-.09-.38,15.57,15.57,0,0,0-1.48-.84l-1.53-.72-.62-.28-.83-.41-.19-.11-.52-.29-1.48-.84-.21-.1-.52-.27-.64-.33-1-.48-.2-.1-.37-.21c-.26-.15-.53-.29-.79-.45l-.83-.48-.56-.32-1-.53-.64-.35c-.22-.12-.44-.23-.65-.36l-.88-.53-.28-.18-.43-.28-.1-.07a.26.26,0,0,0-.18-.23.82.82,0,0,0-.26-.06h0a.23.23,0,0,0-.21.13m13.33,7.71h0' />
            <path d='M507.4,314.76a.22.22,0,0,0-.15.12.33.33,0,0,0,0,.22.28.28,0,0,0,.12.16l.23.12.24.1.39.14.81.28.69.24c.27.09.53.19.81.27l1.57.49,1.73.49.7.19,1,.27c.52.14,1,.26,1.56.38l.28,0,.59.12.69.12.77.13.93.18c.57.1,1.14.22,1.71.35.26.05.53.13.79.19l.33.1a2.26,2.26,0,0,1,.37.11l.53.18.45.17.79.35.13.06a.28.28,0,0,0,.2,0,.24.24,0,0,0,.15-.13.3.3,0,0,0,0-.21.28.28,0,0,0-.11-.17l-.81-.36c-.25-.11-.49-.21-.75-.3l-.48-.17-.4-.11-.85-.22c-.27-.08-.55-.13-.82-.19l-.81-.16-1.48-.28-.95-.16-.9-.17-.65-.14-.82-.2c-.52-.13-1-.26-1.55-.41l-1.69-.47-.85-.25L511,316l-1.51-.5-.91-.32-.48-.17-.36-.15-.14-.07a.28.28,0,0,0-.13,0h-.06' />
            <path d='M506,321.17a.3.3,0,0,0-.12.17.26.26,0,0,0,0,.21.22.22,0,0,0,.15.13l.57.11.32.07.84.21,1.54.43,1.63.43,1.1.29.54.13,1.09.25.57.11L516,324l1,.16.81.16.67.13,1,.21L521,325l.78.16.43.08.43.07c.48.07,1,.13,1.47.17.24,0,.49,0,.73.06l.66.08a2.32,2.32,0,0,1,.38.06.24.24,0,0,0,.31-.19.28.28,0,0,0-.17-.34c-.26-.06-.53-.09-.8-.13l-.71-.06-.83-.07-.69-.09c-.23,0-.47-.06-.7-.11l-.81-.15-1.81-.4c-1-.24-2.08-.45-3.12-.62l-1-.17-.73-.13-.89-.17-.22,0-.56-.13-.83-.19-.25-.07-.56-.14-1.65-.44-1.72-.47-.71-.19-.41-.1-.13,0-.3-.06-.41-.07h-.07a.27.27,0,0,0-.12,0m17,3.68h0Z' />
            <path d='M506.08,321.29a.3.3,0,0,0-.09.38,3.37,3.37,0,0,0,.19.32l.15.25.22.39c.08.13.16.26.23.39s.17.25.25.37a8.38,8.38,0,0,0,.52.66l.28.33a2.72,2.72,0,0,1,.24.24l.1.15a2,2,0,0,0,.41.35,5.3,5.3,0,0,0,1.05.56c.38.15.76.27,1.14.4l.25.08.45.12.72.2,1.76.46,1.66.47c1.06.3,2.13.61,3.2.86.28.07.55.13.83.18l.82.16.41.07.47.06.85.11.76.08.47,0,.39,0c.55,0,1.1,0,1.65,0h1.88l.63,0a.28.28,0,0,0,0-.56l-.8,0h-2.38c-.27,0-.55,0-.83,0l-.46,0c-.15,0-.3,0-.44,0l-.73-.07-.79-.1-.62-.08-.81-.14-.41-.09-.4-.08-.81-.19-.83-.21-.87-.25-.84-.23-1.42-.41-1.75-.47-1.64-.44-.18-.06-.45-.15-.32-.1c-.22-.07-.43-.17-.65-.26a4.29,4.29,0,0,1-.91-.56l-.09-.1-.1-.15a.74.74,0,0,0-.12-.13c-.11-.1-.2-.2-.3-.31s-.37-.45-.54-.68l-.11-.15-.12-.18-.24-.4c-.07-.13-.15-.26-.23-.39l-.14-.25a.61.61,0,0,0-.07-.1.24.24,0,0,0-.07-.18.81.81,0,0,0-.12-.14.41.41,0,0,0-.11-.07h-.07a.28.28,0,0,0-.12,0' />
            <path d='M532.92,323.71a.38.38,0,0,0-.18.09.43.43,0,0,0-.17.13.53.53,0,0,0-.11.15,1.34,1.34,0,0,0-.07.18,1.1,1.1,0,0,0-.07.36.28.28,0,0,0,0,.16.36.36,0,0,0,.11.2.27.27,0,0,0,.16.08.14.14,0,0,0,.09,0,.28.28,0,0,0,.13,0,.81.81,0,0,0,.25-.27,1.79,1.79,0,0,0,.13-.32.17.17,0,0,0,0-.07l0-.1a.58.58,0,0,0,0-.39.3.3,0,0,0-.28-.19h-.06' />
            <path d='M534.11,321.32a.42.42,0,0,0-.16.11.49.49,0,0,0-.1.15,1.42,1.42,0,0,0-.08.18c0,.1,0,.2-.07.3a1.23,1.23,0,0,0,0,.2.33.33,0,0,0,0,.15s0,.08,0,.12a.24.24,0,0,0,.12.16.25.25,0,0,0,.19,0,.39.39,0,0,0,.23-.17l0,0a.23.23,0,0,0,.07-.07,2.26,2.26,0,0,0,.16-.26l.06-.17s0-.07,0-.1a.24.24,0,0,0,0-.12.83.83,0,0,0,0-.22.91.91,0,0,0-.09-.17.56.56,0,0,0-.17-.09h-.19' />
            <path d='M534.15,324.21a.16.16,0,0,0-.08.06,1.25,1.25,0,0,0-.09.12,1.6,1.6,0,0,0-.1.23,1,1,0,0,0-.08.38.71.71,0,0,0,0,.14s0,.05,0,.08a.68.68,0,0,0,0,.14s0,.07,0,.11a.32.32,0,0,0,.08.14.24.24,0,0,0,.26.08.37.37,0,0,0,.17-.1.94.94,0,0,0,.14-.19,1.47,1.47,0,0,0,.1-.22l.06-.19a1.55,1.55,0,0,0,0-.23.37.37,0,0,0,0-.11.29.29,0,0,0,0-.13l0-.1h0a.28.28,0,0,0-.12-.2.2.2,0,0,0-.11,0l-.08,0a.24.24,0,0,0-.14,0' />
            <path d='M537.16,324.26a.3.3,0,0,0-.15.25c0,.13,0,.26,0,.39a.52.52,0,0,0,.11.25.24.24,0,0,0,.2.09h.09l.08,0a.52.52,0,0,0,.19-.3.76.76,0,0,0,0-.16,1.23,1.23,0,0,0,0-.2.94.94,0,0,0-.06-.29.38.38,0,0,0-.12-.13.41.41,0,0,0-.17-.05h0a.26.26,0,0,0-.23.2' />
            <path d='M538.51,323l-.07.06a.24.24,0,0,0-.29.13.29.29,0,0,0,.09.38l.1.07a.67.67,0,0,0,.12.06.38.38,0,0,0,.26,0,.44.44,0,0,0,.22-.29.39.39,0,0,0,0-.26.25.25,0,0,0-.19-.14h-.07a.22.22,0,0,0-.13,0' />
            <path d='M535,330.07a.25.25,0,1,0,.5,0,.25.25,0,1,0-.5,0' />
            <path d='M535.33,332.44h-.11a.28.28,0,0,0-.17.34s0,.06,0,.09a1.09,1.09,0,0,0,0,.17.27.27,0,0,0,.1.11.29.29,0,0,0,.18.07.28.28,0,0,0,.2-.1.42.42,0,0,0,.09-.19.47.47,0,0,0,0-.35.22.22,0,0,0-.11-.12.23.23,0,0,0-.14,0,.15.15,0,0,0-.09,0m.24.36h0' />
            <path d='M537.89,327.93a1.74,1.74,0,0,0-.1.19,1,1,0,0,0-.11.41s0,.07,0,.1l0,.07a.27.27,0,0,0,.16.14.29.29,0,0,0,.16,0l.08,0,.06-.05.08-.1a.16.16,0,0,1,0-.07l0-.11s0-.06,0-.1a.7.7,0,0,0,0-.14h0a.3.3,0,0,0-.09-.38.28.28,0,0,0-.13,0,.24.24,0,0,0-.21.13m.29.6h0' />
            <path d='M536.19,327a.28.28,0,0,0-.18.17l0,.1s0,0,0,0l-.05.14c0,.09,0,.17,0,.25a.46.46,0,0,0,0,.23.25.25,0,0,0,.23.14.35.35,0,0,0,.31-.24c0-.07,0-.15.08-.23a.81.81,0,0,0,0-.38.31.31,0,0,0-.13-.2l-.07,0h-.15' />
            <path d='M533,327.44a.25.25,0,1,0,.25-.28.27.27,0,0,0-.25.28' />
            <path d='M532.79,333.7a.25.25,0,1,0,.25-.28.27.27,0,0,0-.25.28' />
            <path d='M532.92,330.92a.3.3,0,0,0,0,.26,1.5,1.5,0,0,0,0,.2.43.43,0,0,0,.15.38.27.27,0,0,0,.38-.07.64.64,0,0,0,.13-.29.77.77,0,0,0,0-.15,1.15,1.15,0,0,0,0-.19.51.51,0,0,0-.07-.17.36.36,0,0,0-.2-.17h-.08a.37.37,0,0,0-.3.21' />
            <path d='M537.85,330.83l-.06.07a.4.4,0,0,0,0,.15.46.46,0,0,0,0,.23.26.26,0,0,0,.38.24.45.45,0,0,0,.17-.3.47.47,0,0,0,0-.17.38.38,0,0,0,0-.16.26.26,0,0,0-.16-.13l-.06,0a.24.24,0,0,0-.18.09' />
            <path d='M540.17,327.77a.5.5,0,0,0-.17.11l-.09.13a.31.31,0,0,0,0,.21.22.22,0,0,0,.08.14.31.31,0,0,0,.11.16.24.24,0,0,0,.2,0,.27.27,0,0,0,.15-.13l0-.08a.88.88,0,0,0,0-.15.37.37,0,0,0,0-.21.28.28,0,0,0-.12-.18.23.23,0,0,0-.14,0h-.08m.19.51h0' />
            <path d='M539,325.9h0a.3.3,0,0,0-.23.1.91.91,0,0,0-.17.34.27.27,0,0,0,0,.25l0,.06a.28.28,0,0,0,.09.1.25.25,0,0,0,.19,0l.09,0a.29.29,0,0,0,.11-.11.87.87,0,0,0,.07-.17s0,0,0-.05l.09-.08a.26.26,0,0,0,.07-.19.27.27,0,0,0-.07-.2.25.25,0,0,0-.18-.08.24.24,0,0,0-.14,0' />
            <path d='M535.51,330.67a.56.56,0,0,0-.34.3.3.3,0,0,0,0,.21.3.3,0,0,0,.12.17.25.25,0,0,0,.19,0,.22.22,0,0,0,.15-.12v0l0,0,0,.12a.28.28,0,0,0,.17.34.25.25,0,0,0,.31-.19,1.5,1.5,0,0,0,.06-.29.54.54,0,0,0,0-.28.59.59,0,0,0,0-.08.43.43,0,0,0-.11-.12.36.36,0,0,0-.24-.09.49.49,0,0,0-.2.05m.13.45v0h0v0m.09.06h0m0,0h0m0,0h0Z' />
            <path d='M539,333a.24.24,0,0,0-.15.13l-.09.15a1.54,1.54,0,0,0-.08.19.71.71,0,0,0,0,.2.56.56,0,0,0,0,.13s0,.07,0,.11l.06.08a.32.32,0,0,0,.14.08.29.29,0,0,0,.24,0,.65.65,0,0,0,.15-.18.69.69,0,0,0,.05-.13.33.33,0,0,0,0-.17.24.24,0,0,0-.06-.11l0,0a.28.28,0,0,0-.09-.38.19.19,0,0,0-.12,0H539' />
            <path d='M541,328.73a2.75,2.75,0,0,0-.13.47.25.25,0,0,0,0,.08v0a.32.32,0,0,0,0,.21.25.25,0,0,0,.29.13.32.32,0,0,0,.2-.22.68.68,0,0,0,0-.14.36.36,0,0,0,0-.1l.09-.3a.28.28,0,0,0-.17-.34h-.07a.25.25,0,0,0-.24.2' />
            <path d='M540.62,336a.28.28,0,0,0-.18.34.9.9,0,0,0,.11.27.37.37,0,0,0,.12.13.22.22,0,0,0,.16.05.27.27,0,0,0,.15-.05.1.1,0,0,0,0,0,.16.16,0,0,0,.06-.08h0a.29.29,0,0,0,0-.33.22.22,0,0,0-.13-.08h0a.26.26,0,0,0-.25-.2h-.06' />
            <path d='M536.71,337.63a.25.25,0,1,0,.5,0,.25.25,0,1,0-.5,0' />
            <path d='M536.3,335.67a.25.25,0,1,0,.5,0,.25.25,0,1,0-.5,0' />
            <path d='M531.84,334.36a1.11,1.11,0,0,0-.22.34.8.8,0,0,0-.08.4v.1a.29.29,0,0,0,.07.2.25.25,0,0,0,.16.08h.07a.28.28,0,0,0,.13,0,.39.39,0,0,0,.15-.16.61.61,0,0,0,.08-.42l0-.1,0,0a.3.3,0,0,0,.08-.2.27.27,0,0,0-.08-.19.22.22,0,0,0-.18-.08.2.2,0,0,0-.17.08' />
            <path d='M532.71,335.92s0,.06,0,.09a.2.2,0,0,0,0,.13.3.3,0,0,0,0,.13.27.27,0,0,0,.12.24.24.24,0,0,0,.26,0,.35.35,0,0,0,.14-.24.37.37,0,0,0,0-.18v0a.29.29,0,0,0-.18-.34H533a.25.25,0,0,0-.24.2m0,.19v0h0v0m0,0Zm0,0h0v.05h0v0h0v0m0,0h0v0m.49.08h0m0,.08v0h0v0m-.45-.13h0' />
            <path d='M533.86,340.85l-.06.23a.76.76,0,0,0,0,.3.33.33,0,0,0,0,.13l0,.06,0,0a.2.2,0,0,0,.13,0,.37.37,0,0,0,.27-.13.42.42,0,0,0,.14-.25.28.28,0,0,0,0-.09s0-.08,0-.11a.36.36,0,0,0,0-.09.25.25,0,0,0-.08-.1.27.27,0,0,0-.18-.23h-.06a.26.26,0,0,0-.25.21m.12.28h0m.3.14h0Z' />
            <path d='M537.08,332.94a.24.24,0,0,0-.15.13.26.26,0,0,0,0,.21,1.69,1.69,0,0,0,.23.51.35.35,0,0,0,.41.16.43.43,0,0,0,.21-.17.49.49,0,0,0,0-.11s0-.07,0-.11,0-.06,0-.09a.25.25,0,0,0-.17-.19h0a.33.33,0,0,0-.17,0h0a.75.75,0,0,1-.05-.16.33.33,0,0,0-.12-.16.2.2,0,0,0-.13,0h-.06' />
            <path d='M541.87,330.75a.21.21,0,0,0-.12,0h0a.27.27,0,0,0-.25.28,1,1,0,0,0,0,.25.32.32,0,0,0,.21.26.27.27,0,0,0,.3-.09.82.82,0,0,0,.11-.23.19.19,0,0,0,0-.07.29.29,0,0,0,0-.13.47.47,0,0,0,0-.16.26.26,0,0,0-.21-.14h0' />
            <path d='M541.79,336.54a.22.22,0,0,0-.15.13.3.3,0,0,0,0,.21c0,.1,0,.2,0,.3a1,1,0,0,0,0,.17l0,.09.09.12.07.07h0a.19.19,0,0,0,.12,0c.15,0,.26-.1.34-.22a1,1,0,0,0,.13-.34.35.35,0,0,0-.13-.4.23.23,0,0,0-.27,0h0a.25.25,0,0,0-.12-.16.2.2,0,0,0-.13,0h-.06m.38.64h0' />
            <path d='M538,338.83h0a.27.27,0,0,0-.2,0,.34.34,0,0,0-.11.17s0,.05,0,.07a.32.32,0,0,0,0,.14,1,1,0,0,0,.31.47.35.35,0,0,0,.39,0,.49.49,0,0,0,.17-.25.82.82,0,0,0,.07-.28.42.42,0,0,0-.19-.33.39.39,0,0,0-.2-.06.51.51,0,0,0-.23.07' />
            <path d='M537.16,340.74a.29.29,0,0,0-.16.13v0l0,0a.42.42,0,0,0,0,.22.25.25,0,0,0,.12.16h0a.41.41,0,0,0,.09.1l.09,0a.27.27,0,0,0,.27-.08.35.35,0,0,0,0-.47s0,0,0,0a.26.26,0,0,0-.23-.15h-.06' />
            <path d='M539.52,330.92a.24.24,0,0,0-.15.13.3.3,0,0,0,0,.21l0,.17a1.17,1.17,0,0,0,.06.2.39.39,0,0,0,.08.1.16.16,0,0,0,.11.07h.08a.25.25,0,0,0,.14,0l.06,0,.05-.07a.44.44,0,0,0,0-.52.24.24,0,0,0-.14-.09.27.27,0,0,0-.22-.13h-.07' />
            <path d='M541.83,333.81a.35.35,0,0,0-.07.2.31.31,0,0,0,0,.13.61.61,0,0,0,0,.12.25.25,0,0,0,0,.21.24.24,0,0,0,.15.13.24.24,0,0,0,.2,0,.26.26,0,0,0,.11-.16l.09-.23a.43.43,0,0,0-.06-.4.25.25,0,0,0-.19-.08.3.3,0,0,0-.23.11' />
            <path d='M541.62,340.29a.26.26,0,1,0,.26-.28.28.28,0,0,0-.26.28' />
            <path d='M539.69,343.12a2.2,2.2,0,0,0,0,.36.5.5,0,0,0,.17.27.29.29,0,0,0,.33,0,.35.35,0,0,0,.11-.13.5.5,0,0,0,0-.13.42.42,0,0,0-.12-.42l-.06-.05,0,0a.27.27,0,0,0-.22-.14.26.26,0,0,0-.26.27' />
            <path d='M533.44,344.59c0,.05,0,.1,0,.15l0,.1s0,.08,0,.11a.61.61,0,0,0,0,.14.45.45,0,0,0,.23.3.49.49,0,0,0,.2.05.34.34,0,0,0,.22-.1.55.55,0,0,0,.15-.28.51.51,0,0,0-.07-.36.37.37,0,0,0-.12-.13.11.11,0,0,0-.07,0,.26.26,0,0,0-.16-.15h-.06a.28.28,0,0,0-.25.21m.43.36h0Z' />
            <path d='M531.7,347.66a2.19,2.19,0,0,0-.09.5,1.64,1.64,0,0,0,0,.22.49.49,0,0,0,.06.17.42.42,0,0,0,.3.25l.09,0,.11,0,.08,0,.09,0a.46.46,0,0,0,.2-.36.42.42,0,0,0,0-.16.41.41,0,0,0-.14-.2l-.08,0-.09,0-.09,0s0-.1,0-.15a.28.28,0,0,0-.18-.34H532a.26.26,0,0,0-.25.2' />
            <path d='M546.31,339.78a.21.21,0,0,0-.11,0,.29.29,0,0,0-.18.34c0,.06,0,.12.05.19a.7.7,0,0,0,.11.21.29.29,0,0,0,.36.05.5.5,0,0,0,.12-.13.33.33,0,0,0,.05-.12.53.53,0,0,0,0-.28.35.35,0,0,0-.15-.24.27.27,0,0,0-.15-.05l-.1,0' />
            <path d='M544.9,341.45a.25.25,0,1,0,.5,0,.25.25,0,1,0-.5,0' />
            <path d='M541.09,338.87a.26.26,0,1,0,.26-.28.28.28,0,0,0-.26.28' />
            <path d='M545,333.73a.4.4,0,0,0-.22.1.26.26,0,0,0-.11.16.34.34,0,0,0,0,.22.26.26,0,0,0,.15.12.17.17,0,0,0,.14,0,.26.26,0,0,0,.11.07h0a.26.26,0,0,0,.32-.27,1.8,1.8,0,0,1,.05-.19.29.29,0,0,0-.18-.34h-.06a.27.27,0,0,0-.22.14' />
            <path d='M544.06,338a.22.22,0,0,0-.08.19l0,.28s0,.05,0,.08a.6.6,0,0,0,.08.16.28.28,0,0,0,.13.09.17.17,0,0,0,.14,0l.12-.07a.51.51,0,0,0,.12-.23.48.48,0,0,0,0-.29.34.34,0,0,0-.22-.23h0a.23.23,0,0,0-.16-.06.2.2,0,0,0-.17.08' />
            <path d='M538.57,335.86a1.18,1.18,0,0,0-.2.52.5.5,0,0,0,.07.27.25.25,0,0,0,.11.12l.08,0h.08a.3.3,0,0,0,.27-.18.38.38,0,0,0,0-.31.45.45,0,0,0-.06-.12l0-.07a.3.3,0,0,0-.1-.38.17.17,0,0,0-.12,0,.25.25,0,0,0-.22.14m.31.54h0' />
            <path d='M540.89,332.66a.28.28,0,0,0-.11.17c0,.09-.07.19-.1.28l0,.12a1.1,1.1,0,0,0,0,.16.46.46,0,0,0,0,.23.28.28,0,0,0,.09.1.25.25,0,0,0,.19,0l.08,0a.35.35,0,0,0,.11-.13.55.55,0,0,0,.06-.26.36.36,0,0,0,0-.11,2.25,2.25,0,0,0,.08-.24.33.33,0,0,0,0-.22.25.25,0,0,0-.15-.12H541a.28.28,0,0,0-.13,0' />
            <path d='M540.7,326.34l0,.12v.05a.37.37,0,0,0,0,.11h0a.33.33,0,0,0,.14.29.23.23,0,0,0,.14,0,.25.25,0,0,0,.18-.08.39.39,0,0,0,.09-.24.29.29,0,0,0,0-.09s0,0,0,0v0a.33.33,0,0,0,0-.22.24.24,0,0,0-.15-.13H541a.25.25,0,0,0-.25.2' />
            <path d='M543.12,342c0,.11,0,.22,0,.32a1.08,1.08,0,0,0,0,.36.34.34,0,0,0,.09.19.25.25,0,0,0,.19.09.32.32,0,0,0,.28-.22,1.28,1.28,0,0,0,.08-.35.49.49,0,0,0,0-.17l0-.11a.47.47,0,0,0-.1-.1h0a.25.25,0,1,0-.5,0m.53.41h0m0,0h0' />
            <path d='M541.48,342.21h0a.28.28,0,0,0-.25.28,3.13,3.13,0,0,0,0,.44.37.37,0,0,0,.24.32.37.37,0,0,0,.18,0c.09,0,.14-.11.19-.18a.22.22,0,0,0,0-.11.42.42,0,0,0,0-.1.77.77,0,0,0,0-.15.55.55,0,0,0-.05-.33.3.3,0,0,0-.28-.21.23.23,0,0,0-.14,0' />
            <path d='M544.38,344.61a.75.75,0,0,0-.07.12l-.06.17,0-.07-.07.19a.33.33,0,0,0,0,.22.2.2,0,0,0,.15.12.25.25,0,0,0,.19,0l0,0a.32.32,0,0,0,.16-.09.44.44,0,0,0,.08-.11.57.57,0,0,0,0-.46.28.28,0,0,0-.18-.2h-.06a.27.27,0,0,0-.24.18' />
            <path d='M537.94,349.07l-.07.07a.25.25,0,0,0-.17,0,.28.28,0,0,0-.17.34,3.38,3.38,0,0,0,.11.34.74.74,0,0,0,.24.39l.09,0a.25.25,0,0,0,.17,0,.12.12,0,0,0,.08,0,.41.41,0,0,0,.11-.12.88.88,0,0,0,0-.87.38.38,0,0,0-.19-.16h-.08a.34.34,0,0,0-.17.05m.34.34v0a.06.06,0,0,1,0,0' />
            <path d='M536.07,351.08c0,.07,0,.14,0,.21a.5.5,0,0,0,.08.2.43.43,0,0,0,.21.19.3.3,0,0,0,.32-.08.4.4,0,0,0,.09-.35.54.54,0,0,0-.1-.2.33.33,0,0,0-.15-.11.25.25,0,0,0-.22-.14.27.27,0,0,0-.25.28' />
            <path d='M542.44,345.9a.28.28,0,0,0-.17.34,2.41,2.41,0,0,0,.08.25.28.28,0,0,0,.11.17.27.27,0,0,0,.2,0,.25.25,0,0,0,.15-.12.33.33,0,0,0,0-.22c0-.08-.05-.16-.08-.25a.25.25,0,0,0-.24-.2h-.07' />
            <path d='M545,347.69a.63.63,0,0,0-.17.19.31.31,0,0,0,0,.21.28.28,0,0,0,.09.14l0,.09.05.07a.41.41,0,0,0,.11.07.23.23,0,0,0,.21,0,.49.49,0,0,0,.17-.25.75.75,0,0,0,0-.2.34.34,0,0,0-.16-.3.28.28,0,0,0-.15,0,.41.41,0,0,0-.18.05' />
            <path d='M541.54,351.51a.27.27,0,0,0-.17.32.2.2,0,0,0,0,.11.26.26,0,0,0,.07.19s0,0,0,0a.46.46,0,0,0,.08.13.25.25,0,0,0,.38,0A.35.35,0,0,0,542,352a.71.71,0,0,0-.08-.15.43.43,0,0,1,0-.11.26.26,0,0,0-.25-.2h-.07m0,.7h0m0,0h0' />
            <path d='M539.73,349.32a.25.25,0,1,0,.5,0,.25.25,0,1,0-.5,0' />
            <path d='M539.07,352.43a.27.27,0,0,0-.15.13.31.31,0,0,0,0,.21l0,.08,0,0v0l0,.05v0h0a.53.53,0,0,0,.18.26.26.26,0,0,0,.29,0,.36.36,0,0,0,.15-.23.45.45,0,0,0,0-.23.39.39,0,0,0-.14-.24.27.27,0,0,0-.23,0l-.07,0h-.07m.43.38h0m-.59,0,0,.09,0,0v0h0v0h0v-.05h0m.57.06h0m-.54,0h0m0,0h0' />
            <path d='M537.92,354.7l-.12.43a.81.81,0,0,0,0,.41.3.3,0,0,0,.2.21.24.24,0,0,0,.2,0,.28.28,0,0,0,.09-.07.26.26,0,0,0,.07-.19.28.28,0,0,0-.06-.18c0-.15.07-.29.11-.43a.28.28,0,0,0-.17-.34h-.07a.25.25,0,0,0-.24.2m0,.57h0' />
            <path d='M536.39,345.69a.34.34,0,0,0-.31.28.31.31,0,0,0,0,.21v0l0,.05a.29.29,0,0,0,.18.34.25.25,0,0,0,.31-.19,1.34,1.34,0,0,0,.07-.27.49.49,0,0,0,0-.12.32.32,0,0,0,0-.16.35.35,0,0,0-.1-.11.21.21,0,0,0-.13-.05Z' />
            <path d='M539.68,345.21a.3.3,0,0,0-.08.2c0,.1,0,.2,0,.3a1.09,1.09,0,0,0,.07.24.75.75,0,0,0,.07.12.31.31,0,0,0,.16.11h.17a.34.34,0,0,0,.18-.11.56.56,0,0,0,.07-.18.39.39,0,0,0,0-.26.3.3,0,0,0-.11-.14l-.08,0h0v0a.36.36,0,0,0-.08-.2.23.23,0,0,0-.17-.08.23.23,0,0,0-.18.08' />
            <path d='M537,342.92a.29.29,0,0,0-.15.12.4.4,0,0,0,0,.15.28.28,0,0,0,0,.32,1.19,1.19,0,0,0,.08.16.28.28,0,0,0,.24.15.28.28,0,0,0,.25-.17.64.64,0,0,0,0-.43.57.57,0,0,0-.11-.22.26.26,0,0,0-.2-.1l-.08,0' />
            <path d='M535,348.23a1.37,1.37,0,0,0,0,.28.31.31,0,0,0,.2.25.24.24,0,0,0,.26-.07l0-.06a.42.42,0,0,0,0-.16.3.3,0,0,0,0-.16v-.08a.27.27,0,0,0-.25-.28.28.28,0,0,0-.26.28' />
            <path d='M532.72,339.86l0,.05a.35.35,0,0,0,0,.14l0,.28v-.07a1.23,1.23,0,0,1,0,.19.66.66,0,0,0,0,.28.28.28,0,0,0,.11.17l.06,0a.21.21,0,0,0,.17,0,.22.22,0,0,0,.15-.13.3.3,0,0,0,0-.21l0-.09c0-.05,0-.09,0-.13l0-.33a.26.26,0,0,0-.07-.19.24.24,0,0,0-.36,0m.39.52v0h0m0,0v0' />
            <path d='M534.79,338a.29.29,0,0,0,0,.32.36.36,0,0,1,0,.1.26.26,0,0,0,.31.19.28.28,0,0,0,.18-.34.54.54,0,0,0-.2-.37.19.19,0,0,0-.12,0,.24.24,0,0,0-.22.14' />
            <path d='M543.66,334.79a.3.3,0,0,0-.17.35.67.67,0,0,0,.14.35.27.27,0,0,0,.33.05.54.54,0,0,0,.14-.16.44.44,0,0,0,.06-.23s0-.07,0-.1a.39.39,0,0,0-.08-.14.2.2,0,0,0-.19-.08.23.23,0,0,0-.14,0h-.07' />
            <path d='M543.12,331.81a.26.26,0,1,0,.26-.28.28.28,0,0,0-.26.28' />
            <path d='M542.54,329a.26.26,0,1,0,.25-.27.27.27,0,0,0-.25.27' />
            <path d='M507.84,318.42a.26.26,0,0,0-.07.19c0,.3,0,.6-.07.9l0,.58c0,.21,0,.42,0,.63a5.56,5.56,0,0,0,.08.7q0,.3.12.6c.06.22.13.44.2.65a9.89,9.89,0,0,0,.55,1.19c.2.36.43.7.66,1,.11.15.21.31.31.47a.33.33,0,0,0,.16.13.22.22,0,0,0,.19,0,.3.3,0,0,0,.12-.17.31.31,0,0,0,0-.21l-.49-.74c-.13-.2-.26-.4-.38-.6a7.18,7.18,0,0,1-.58-1.18,5.94,5.94,0,0,1-.23-.75,5.64,5.64,0,0,1-.15-.82,5.92,5.92,0,0,1,0-1l0-.51c0-.28,0-.56.06-.85a.26.26,0,0,0-.07-.19.27.27,0,0,0-.18-.08.25.25,0,0,0-.18.08m.77,4.18v0' />
            <path d='M508.16,314.4a.28.28,0,0,0-.11.17c-.07.2-.13.4-.19.61a2.47,2.47,0,0,0-.11.47c0,.14,0,.29,0,.43a.27.27,0,0,0,.25.28.25.25,0,0,0,.18-.08.36.36,0,0,0,.08-.2c0-.1,0-.21,0-.32a3.8,3.8,0,0,1,.15-.58c0-.16.09-.31.14-.46a.33.33,0,0,0,0-.22.29.29,0,0,0-.15-.12h-.07a.28.28,0,0,0-.13,0' />
            <path d='M511,310.52c-.12.13-.23.25-.33.38l-.19.23c-.05.06-.09.13-.14.2s-.2.29-.3.43-.09.16-.14.23a3.59,3.59,0,0,0-.24.53l0,.06a.38.38,0,0,0,0,.15.3.3,0,0,0,.12.17.24.24,0,0,0,.19,0,.2.2,0,0,0,.15-.12c.07-.18.14-.34.22-.51l.12-.19.21-.31.16-.23a1.63,1.63,0,0,1,.17-.2c.12-.16.24-.3.37-.45a.29.29,0,0,0,0-.39.25.25,0,0,0-.18-.08.23.23,0,0,0-.17.08' />
            <path d='M513.77,307.69a.26.26,0,0,0-.15.12l-.17.24a.28.28,0,0,0,0,.22.21.21,0,0,0,.11.16.24.24,0,0,0,.2,0,.27.27,0,0,0,.15-.13l.16-.23a.33.33,0,0,0,0-.22.25.25,0,0,0-.12-.16.2.2,0,0,0-.13,0h-.06' />
            <path d='M508.62,322.9a.27.27,0,0,0-.08.19.3.3,0,0,0,.08.2c.49.66,1,1.34,1.5,2a.25.25,0,0,0,.36,0,.32.32,0,0,0,0-.4c-.52-.64-1-1.31-1.51-2a.21.21,0,0,0-.18-.08.23.23,0,0,0-.17.08' />
            <path d='M509.26,322.41a.29.29,0,0,0-.09.38c.07.12.15.24.23.35a3.15,3.15,0,0,0,.31.41l.26.32.42.52.87,1.16a.22.22,0,0,0,.18.08.27.27,0,0,0,.18-.08.32.32,0,0,0,.07-.19.35.35,0,0,0-.07-.2l-.78-1-.38-.48-.25-.31c-.14-.16-.27-.32-.4-.49s-.14-.22-.2-.33a.28.28,0,0,0-.23-.14.19.19,0,0,0-.12,0' />
            <path d='M510.16,322.75a.31.31,0,0,0-.12.16.33.33,0,0,0,0,.22c.15.22.3.45.46.67l.36.5c.26.38.52.76.8,1.13a7.05,7.05,0,0,0,.69.75c.15.15.3.3.46.44a.23.23,0,0,0,.17.08.23.23,0,0,0,.18-.08.27.27,0,0,0,.08-.19.3.3,0,0,0-.08-.2l-.66-.65a5.37,5.37,0,0,1-.58-.67l-.25-.36-.44-.63-.46-.63-.3-.44a.27.27,0,0,0-.15-.13h-.06a.2.2,0,0,0-.13,0m1.51,1.77,0,0,0,0' />
            <path d='M511.51,323.37a.31.31,0,0,0-.09.38c.17.27.35.52.54.77l.53.7c.17.22.35.44.54.65l.65.65.24.23a.25.25,0,0,0,.18.08.27.27,0,0,0,.18-.08.3.3,0,0,0,0-.39c-.22-.2-.43-.41-.64-.62s-.35-.36-.51-.56c-.35-.42-.68-.87-1-1.31-.09-.13-.18-.26-.26-.4a.31.31,0,0,0-.15-.13h-.07a.33.33,0,0,0-.13,0' />
            <path d='M512.52,323.2a.31.31,0,0,0-.07.2.29.29,0,0,0,.07.2c.21.26.42.53.62.8l.51.71a6.42,6.42,0,0,0,.53.64c.21.22.42.43.65.63l.09.06a.21.21,0,0,0,.19,0,.27.27,0,0,0,.15-.13.26.26,0,0,0,0-.21.3.3,0,0,0-.12-.17l-.07,0a2.39,2.39,0,0,1-.23-.22l-.34-.33a6.32,6.32,0,0,1-.52-.64l-.51-.71c-.2-.28-.42-.54-.62-.81a.25.25,0,0,0-.18-.08.27.27,0,0,0-.18.08' />
            <path d='M513.86,323.27a.3.3,0,0,0-.09.38c.32.53.67,1,1,1.53a9.48,9.48,0,0,0,1.09,1.27c.15.16.3.31.45.48a.25.25,0,0,0,.36,0,.3.3,0,0,0,0-.4l-.47-.49c-.16-.15-.31-.32-.46-.48-.29-.32-.54-.68-.8-1l-.36-.53c-.14-.21-.27-.42-.4-.63a.25.25,0,0,0-.22-.14.22.22,0,0,0-.13,0' />
            <path d='M515,323.16a.3.3,0,0,0-.09.38l.66,1c.16.25.34.5.52.74s.44.55.67.8q.62.66,1.2,1.32l.1.11a.24.24,0,0,0,.36,0,.29.29,0,0,0,0-.39l-.87-1-.42-.46a10.3,10.3,0,0,1-.92-1.15c-.15-.2-.28-.41-.42-.62l-.45-.71a.27.27,0,0,0-.15-.13h-.06a.28.28,0,0,0-.13,0' />
            <path d='M516.47,323.84a.26.26,0,0,0-.11.16.33.33,0,0,0,0,.22l.31.46.24.34c.11.14.21.29.31.43l.25.32.47.58.56.72.3.44.36.53c.15.21.3.43.44.65a.27.27,0,0,0,.15.13.24.24,0,0,0,.2,0,.27.27,0,0,0,.11-.16.33.33,0,0,0,0-.22l-.45-.67-.38-.56h0l-.3-.43h0l0,0v0c-.12-.18-.26-.36-.39-.53l-.45-.56-.43-.54c-.13-.16-.24-.33-.36-.5s-.31-.44-.46-.67a.27.27,0,0,0-.15-.13h-.07a.22.22,0,0,0-.13,0m.83.8v0m1.57,2,0,0h0l0,0m-.39.33v0h0v0m.31.45,0,0h0l0,0m.44-.28,0,0,0,0m0,0v0' />
            <path d='M517.85,324.11a.28.28,0,0,0-.09.38q.25.43.54.87a9.61,9.61,0,0,0,.66.88l.57.71c.32.39.63.79.92,1.2a.24.24,0,0,0,.36,0,.27.27,0,0,0,.07-.2.32.32,0,0,0-.07-.19c-.27-.37-.55-.74-.84-1.09s-.79-.95-1.15-1.46c-.22-.32-.43-.66-.63-1a.25.25,0,0,0-.22-.14.19.19,0,0,0-.12,0m.95,1.07,0,0,0,0' />
            <path d='M518.52,323.58a.3.3,0,0,0-.09.38c.1.18.21.35.32.52s.14.22.22.33.33.48.51.72l1.09,1.46.84,1.18,0,0a.27.27,0,0,0,.15.13.25.25,0,0,0,.31-.19.28.28,0,0,0,0-.22c-.35-.49-.69-1-1-1.46-.17-.23-.34-.47-.52-.7l-.54-.72-.44-.63-.47-.74a.23.23,0,0,0-.15-.12h-.06a.28.28,0,0,0-.13,0m2.85,4.54,0,0,0,0' />
            <path d='M520.11,324.52a.3.3,0,0,0-.12.17.25.25,0,0,0,0,.21c.23.36.46.71.7,1.06s.35.49.53.73c.34.47.68.93,1,1.37l.41.48a.25.25,0,0,0,.36,0,.32.32,0,0,0,0-.39c-.66-.74-1.24-1.55-1.83-2.35l-.78-1.18a.26.26,0,0,0-.15-.12h-.06a.28.28,0,0,0-.13,0' />
            <path d='M521.47,325.48a.3.3,0,0,0,0,.39l1,1.24.52.64c.16.19.31.38.49.56a.23.23,0,0,0,.35,0,.3.3,0,0,0,0-.4c-.17-.17-.32-.36-.47-.54l-.53-.65c-.34-.41-.66-.83-1-1.24a.24.24,0,0,0-.36,0' />
            <path d='M522.56,325.48a.28.28,0,0,0-.08.2.27.27,0,0,0,.08.19l.58.81.45.61.32.41c.07.1.16.2.24.3s.34.42.53.61a.25.25,0,0,0,.36,0,.32.32,0,0,0,0-.4,7.31,7.31,0,0,1-.52-.59l-.25-.3-.31-.41-.46-.62-.58-.81a.24.24,0,0,0-.36,0' />
            <path d='M523.58,325.26a.26.26,0,0,0-.07.19.35.35,0,0,0,.07.2l1,1.37.54.71c.17.21.34.43.5.65a.24.24,0,0,0,.36,0,.27.27,0,0,0,.07-.2A.32.32,0,0,0,526,328c-.16-.22-.33-.43-.49-.64l-.54-.71-1-1.38a.22.22,0,0,0-.18-.08.25.25,0,0,0-.18.08' />
            <path d='M524.76,325.29a.3.3,0,0,0-.12.17.25.25,0,0,0,0,.21l1.05,1.59a3,3,0,0,0,.2.29,3.46,3.46,0,0,0,.22.31c.17.23.35.45.53.67a.24.24,0,0,0,.36,0,.29.29,0,0,0,0-.39c-.16-.19-.31-.38-.46-.58l-.38-.52c-.36-.55-.72-1.1-1.09-1.65a.26.26,0,0,0-.15-.12h-.07a.27.27,0,0,0-.12,0' />
            <path d='M525.53,325.42a.3.3,0,0,0-.12.17.31.31,0,0,0,0,.21l.77,1.14c.23.33.46.67.71,1a.23.23,0,0,0,.35,0,.27.27,0,0,0,.08-.19.3.3,0,0,0-.08-.2l-.4-.55,0,0,0,0h0c-.34-.48-.67-1-1-1.45a.31.31,0,0,0-.15-.13h-.07a.2.2,0,0,0-.13,0m1.34,1.55h0Z' />
            <path d='M525.91,325.14a.32.32,0,0,0-.07.2.34.34,0,0,0,.07.19c.23.29.44.59.68.87a.25.25,0,0,0,.36,0,.32.32,0,0,0,0-.4c-.24-.27-.46-.57-.68-.86a.25.25,0,0,0-.18-.08.27.27,0,0,0-.18.08' />
            <path d='M507.06,321.37a.31.31,0,0,0-.09.38l.75,1.23a.31.31,0,0,0,.15.13.24.24,0,0,0,.2,0,.31.31,0,0,0,.09-.38l-.75-1.23a.29.29,0,0,0-.15-.12h-.07a.31.31,0,0,0-.13,0' />
            <path d='M505.87,319.8a.14.14,0,0,0,0,.1.18.18,0,0,0,0,.1l.33.4c.08.1.17.19.25.28a6,6,0,0,0,.6.53.11.11,0,0,0,.17,0,.12.12,0,0,0,0-.1.26.26,0,0,0-.05-.09l-.15-.11-.12-.11-.12-.11-.09-.09-.15-.15-.11-.12-.09-.11c-.1-.12-.21-.24-.3-.37a.18.18,0,0,0-.09,0,.15.15,0,0,0-.09,0' />
            <path d='M505.89,318.49a.13.13,0,0,0-.05.09.11.11,0,0,0,0,.1l.37.54.22.3.32.42.12.13.18.22c.1.11.19.22.29.32l.18.19.24.22a.12.12,0,0,0,.1,0,.11.11,0,0,0,.07-.06.14.14,0,0,0,0-.11.16.16,0,0,0-.06-.08l0,0c-.11-.1-.21-.21-.31-.32l-.3-.32-.12-.15-.18-.21-.08-.1-.19-.24-.12-.16-.09-.12c-.09-.13-.18-.27-.28-.39l-.13-.21a.12.12,0,0,0-.08-.06h0l-.07,0' />
            <path d='M505.92,318.07a.15.15,0,0,0,0,.19l.43.5.27.32.36.4.27.28.33.36.16.16.23.2a.12.12,0,0,0,.1,0,.11.11,0,0,0,.07-.06.2.2,0,0,0,0-.11.16.16,0,0,0-.06-.08l-.05,0a3.5,3.5,0,0,1-.28-.27l-.3-.32a4,4,0,0,1-.28-.3c-.13-.13-.25-.27-.38-.41l-.26-.31-.45-.52a.09.09,0,0,0-.08,0,.13.13,0,0,0-.1,0m2.12,2.12h0' />
            <path d='M506.1,316.84a.16.16,0,0,0-.05.19l.31.54a8.13,8.13,0,0,0,.59.82l.36.39.3.31.21.19a.12.12,0,0,0,.1,0,.18.18,0,0,0,.08-.07.17.17,0,0,0,0-.1A.18.18,0,0,0,508,319h0c-.16-.14-.31-.3-.46-.46a8,8,0,0,1-.65-.77c-.2-.29-.38-.61-.56-.92a.13.13,0,0,0-.11-.06h-.06' />
            <path d='M506.3,315.87a.14.14,0,0,0-.05.19l.64,1,.09.13.19.26.09.13c.06.06.11.13.16.19l.3.35a1.07,1.07,0,0,0,.14.16,1.71,1.71,0,0,0,.2.18.18.18,0,0,0,.1,0,.12.12,0,0,0,.07-.07.14.14,0,0,0,0-.11.16.16,0,0,0-.06-.08l0,0-.12-.12-.14-.15c-.09-.11-.19-.22-.28-.34s-.11-.13-.16-.19a.76.76,0,0,1-.08-.11l-.27-.36c-.08-.14-.17-.28-.26-.42l-.37-.59a.13.13,0,0,0-.11-.07.07.07,0,0,0-.06,0' />
            <path d='M506.17,314.45a.15.15,0,0,0-.06.09.12.12,0,0,0,0,.1l.37.53.23.3.36.44a3.33,3.33,0,0,0,.25.28l.2.22.28.3a6.51,6.51,0,0,0,.54.51.09.09,0,0,0,.09,0,.16.16,0,0,0,.08-.06.2.2,0,0,0,0-.11.16.16,0,0,0-.06-.08l0,0a3.83,3.83,0,0,1-.29-.28l-.33-.33-.27-.29-.2-.23-.07-.08-.26-.31-.12-.15-.05-.06c-.13-.19-.28-.37-.41-.57v0l0,0h0l-.1-.14a.13.13,0,0,0-.07-.06h-.1m.28.2h0' />
            <path d='M506.74,313.84a.15.15,0,0,0-.05.19l.21.36.11.18a.41.41,0,0,0,.07.11l0,0,.28.4c.08.12.17.23.26.35l.23.31.13.16a1,1,0,0,0,.27.26.11.11,0,0,0,.17-.05.15.15,0,0,0,0-.19l-.05,0a2.75,2.75,0,0,1-.22-.26l-.19-.24-.14-.19-.18-.23-.3-.45a2.26,2.26,0,0,1-.16-.26l-.21-.36a.14.14,0,0,0-.11-.06h-.06m.52.65,0,0,0,0m.32.47h0' />
            <path d='M506.9,313a.14.14,0,0,0,0,.09.17.17,0,0,0,0,.1l.71.93.24.31.24.31c.28.34.56.68.87,1a.11.11,0,0,0,.17,0,.14.14,0,0,0,0-.19l-.33-.37-.26-.29-.18-.23-.12-.14-.3-.4-.86-1.11a.09.09,0,0,0-.08,0,.13.13,0,0,0-.1,0' />
            <path d='M508,313a.14.14,0,0,0,0,.19,2.33,2.33,0,0,0,.28.36l.17.21.25.3.58.69c.14.15.27.31.4.46a.13.13,0,0,0,.18,0,.15.15,0,0,0,0-.2c-.38-.44-.76-.88-1.13-1.33l-.23-.27-.21-.25a.11.11,0,0,0,0-.08l-.07-.06a.1.1,0,0,0-.05,0h0a.07.07,0,0,0-.06,0' />
            <path d='M507.86,311.16a.15.15,0,0,0-.05.19l.33.52.2.31.32.47a.86.86,0,0,0,.07.1l.18.23.23.3c.11.12.22.24.32.37l.15.16.12.13.38.37a.15.15,0,0,0,.09,0,.18.18,0,0,0,.09,0,.18.18,0,0,0,0-.2c-.13-.11-.25-.24-.38-.37l-.12-.13-.15-.16-.33-.37-.24-.32-.19-.24-.19-.28-.19-.28c-.15-.25-.31-.5-.47-.75a.12.12,0,0,0-.07-.07h0a.07.07,0,0,0-.06,0' />
            <path d='M508.4,310.36a.15.15,0,0,0,0,.19l.67,1.09.15.24.33.5c.06.1.14.2.21.3l.19.25.07.09c.2.24.39.48.6.71l.2.19a.12.12,0,0,0,.18,0,.15.15,0,0,0,0-.1.15.15,0,0,0,0-.09,3.34,3.34,0,0,1-.32-.33l-.28-.33c-.09-.11-.18-.21-.26-.32l-.34-.45v0l-.24-.37c-.07-.11-.15-.22-.22-.34s-.33-.54-.5-.81l-.21-.35a.15.15,0,0,0-.11-.07l-.06,0' />
            <path d='M508.84,309.51a.1.1,0,0,0-.06.08.21.21,0,0,0,0,.11.59.59,0,0,0,0,.08l.06.06.35.52c.16.21.31.43.47.65h0l.07.1c.06.07.11.15.17.23l.33.43.23.3c.08.1.16.21.25.31s.22.27.34.4.22.26.34.38a.11.11,0,0,0,.17,0,.15.15,0,0,0,0-.2q-.32-.33-.6-.69c-.09-.1-.17-.21-.25-.31l-.29-.37c-.19-.25-.38-.51-.58-.76l-.25-.35-.26-.36c-.12-.18-.23-.36-.35-.53v0l0,0a.14.14,0,0,0-.08-.07h0a.07.07,0,0,0-.06,0m.85,1.47h0' />
            <path d='M509.43,308.53a.16.16,0,0,0-.06.08.15.15,0,0,0,0,.11c.24.37.49.73.74,1.1l.17.25.36.47a1.84,1.84,0,0,0,.17.22l.23.27c.18.21.37.42.56.62a.13.13,0,0,0,.18,0,.16.16,0,0,0,0-.2l-.35-.37-.14-.16-.11-.13-.32-.39-.08-.1-.18-.25-.18-.23-.35-.51c-.16-.24-.32-.49-.49-.73a.13.13,0,0,0-.07-.06h0l-.06,0' />
            <path d='M510,307.92a.11.11,0,0,0,0,.09.15.15,0,0,0,0,.1l.15.21.43.62h0l.46.67h0l.09.11.19.26.14.18a.76.76,0,0,0,.08.11l.29.34.31.35.18.18a.18.18,0,0,0,.09,0,.13.13,0,0,0,.09,0,.14.14,0,0,0,0-.2,2.65,2.65,0,0,1-.33-.34l-.27-.31-.19-.23-.08-.1-.34-.45-.06-.09-.46-.65h0l0,0h0v0h0v0h0l-.43-.62,0,0-.17-.24a.11.11,0,0,0-.09,0,.1.1,0,0,0-.09,0m.13.37,0,0,0,0m.45.65h0m.65.47h0m0,0h0m0,0Zm0,0h0m0,0h0Zm-.21.15h0m-.26-.82h0v0' />
            <path d='M510.72,307.47a.15.15,0,0,0,0,.1.15.15,0,0,0,0,.09l.6.81.33.46.25.31.17.22a3.47,3.47,0,0,1,.25.29l.11.13.09.1a1.59,1.59,0,0,1,.13.13l.18.17a.12.12,0,0,0,.1,0,.11.11,0,0,0,.07-.06.14.14,0,0,0,0-.11A.12.12,0,0,0,513,310l0,0-.29-.29-.3-.36c0-.05-.09-.09-.13-.15l-.13-.16-.25-.32-.94-1.27a.12.12,0,0,0-.18,0m2.21,2.54h0m0,0v0' />
            <path d='M511.43,306.84s-.05.05-.06.08a.21.21,0,0,0,0,.11l.24.34h0l0,0,0,0h0l.37.52h0l0,0v0h0l.18.23.16.21.21.26a1.92,1.92,0,0,0,.28.29l.07,0a.22.22,0,0,0,.11,0,.26.26,0,0,0,.17-.12.18.18,0,0,0,0-.1.21.21,0,0,0,0-.1.12.12,0,0,0-.18,0l0,0h0a4.15,4.15,0,0,1-.27-.32,1.63,1.63,0,0,1-.17-.2c-.11-.13-.21-.27-.31-.4l0,0-.37-.53h0v0h0v0h0c-.06-.1-.13-.19-.2-.29a.16.16,0,0,0-.07-.07h0a.07.07,0,0,0-.06,0m.18.52h0m.6.41h0m0,0h0m0,0h0m.81.93h0m-1-.77h0m0,0h0l0,0m0,0h0m-.2-.73h0' />
            <path d='M512.19,306.88a.15.15,0,0,0,0,.2c.13.18.27.35.4.52l.15.19.11.12.18.21a1.85,1.85,0,0,1,.14.14l.33.3.14.1a.13.13,0,0,0,.18-.05.15.15,0,0,0-.05-.19l-.11-.08-.08-.06-.14-.13-.15-.15-.11-.11-.19-.22-.23-.28c-.14-.17-.26-.34-.39-.51a.12.12,0,0,0-.18,0' />
            <path d='M512.92,306a.14.14,0,0,0,0,.19l.28.42c.07.11.15.21.23.32l.2.25.12.15.17.18a.13.13,0,0,0,.18,0,.17.17,0,0,0,0-.2,2.09,2.09,0,0,1-.2-.22,1.46,1.46,0,0,1-.16-.19l-.3-.39-.3-.46A.14.14,0,0,0,513,306h0l-.06,0' />
            <path d='M530.87,316.58a.14.14,0,0,0,.09.17l1.21.33.28.08.42.13.51.17.48.18.07,0a.11.11,0,0,0,.17-.05.14.14,0,0,0,0-.19,1.59,1.59,0,0,0-.27-.12l-.19-.07-.38-.13-.37-.13-.49-.15-.15,0-.37-.1-.82-.22h0a.13.13,0,0,0-.12.1' />
            <path d='M530,316.81a.14.14,0,0,0,.09.17c.31.08.62.15.92.24l.29.08.15,0,.44.14.3.11.25.09a.14.14,0,0,0,.13.13c.07,0,.17,0,.23-.05a.17.17,0,0,0-.07-.26l-.27-.11-.42-.14-.41-.14-.36-.11-.44-.12-.67-.17h0a.14.14,0,0,0-.13.1' />
            <path d='M529.66,317.12a.17.17,0,0,0-.06.12.14.14,0,0,0,0,.07.13.13,0,0,0,.07.06l.27.11.3.09.22.07.54.15.71.2.66.2.31.1.36.13,0,0a.1.1,0,0,0,.08.05.13.13,0,0,0,.13-.14.15.15,0,0,0,0-.09l0-.06-.11-.05-.12,0-.21-.07-.37-.12-.76-.22-.63-.18.13,0a.13.13,0,0,0,.09,0,.14.14,0,0,0,0-.1.12.12,0,0,0,0-.1.1.1,0,0,0-.09,0l-.72-.1-.29,0-.17,0h-.18a.28.28,0,0,0-.13,0' />
            <path d='M529.41,317.77a.16.16,0,0,0-.06.08.15.15,0,0,0,.09.18q.93.18,1.86.39l.39.09c-.56-.09-1.13-.19-1.69-.3l-.29-.06-.38-.06-.33,0-.43-.06a.15.15,0,0,0-.12.1.15.15,0,0,0,.09.18l.26.07.28.06.34.07.87.18.57.12a4.11,4.11,0,0,1,.6.17l0,0a.13.13,0,0,0,.18,0,.2.2,0,0,0,0-.11h0l.35,0,.29,0,.31,0h.11l.08,0h0l.06,0,0-.06a.17.17,0,0,0,0-.14l0,0-.07,0-.11,0-.12,0-.18-.05-.42-.11-.77-.17-1.68-.35h0l-.06,0m2.45.76h0m1,.3h0' />
            <path d='M528.55,320.93a2.83,2.83,0,0,0-.63.13.13.13,0,0,0-.09.17.12.12,0,0,0,.15.09l.42-.1a2.16,2.16,0,0,1,.36,0l.5.1c.13,0,.25.05.37.09l.83.25,1,.34a.11.11,0,0,0,.15-.09.13.13,0,0,0-.09-.17l-.44-.16-.37-.13-.38-.13-.41-.12-.38-.1-.33-.08a3.1,3.1,0,0,0-.61-.09h0m-.16.29h0' />
            <path d='M528.06,321.45a.14.14,0,0,0,.09.17l1,.26.13,0,.28.08.33.1.32.12.17.06-.07,0a1.83,1.83,0,0,1-.32-.07l-.3-.07-.52-.13-.79-.18-.22,0a.12.12,0,0,0-.14.06.15.15,0,0,0,0,.19l.06,0,.07,0,.12.05a1.61,1.61,0,0,0,.29.09l.17.06.54.15.17.05-.59-.11-.38-.07-.14,0c-.12,0-.24,0-.37,0a.1.1,0,0,0-.1.06.12.12,0,0,0,0,.14.37.37,0,0,0,0,.11.94.94,0,0,0,.1.1.82.82,0,0,0,.25.12l.23.08.28.09.48.15-.76-.12-.62-.09-.13,0h-.45l-.49-.07s-.06,0-.1,0a.15.15,0,0,0-.06.09.18.18,0,0,0,0,.1.12.12,0,0,0,.07.07l.53.08h.44l1.53.24.7.12.26,0,.4.06h.38a.1.1,0,0,0,.1-.07.13.13,0,0,0,0-.16l-.06-.05-.06,0-.12,0a.45.45,0,0,1-.11,0l-.24-.06-.42-.12-1.07-.32-.31-.11.37.07.86.17.38.07.28,0,.25,0a1.9,1.9,0,0,0,.25,0l.06,0a.14.14,0,0,0,0-.09.15.15,0,0,0,0-.07s-.05,0-.08-.07l-.13-.05-.14,0-.06,0,.15-.08a.14.14,0,0,0,.06-.12.13.13,0,0,0-.06-.12c-.08-.05-.15-.11-.23-.15l-.3-.12-.18-.07-.27-.1-.1,0-.53-.15-.29-.08-.84-.21h0a.13.13,0,0,0-.13.1m-.61,1.37h0m0,.27h0m0,0h0m.29,0h0' />
            <path d='M527.43,325.47a.16.16,0,0,0,0,.19l.14.25.17.26.12.22.08.1.34.49.08.1.13.17a8.24,8.24,0,0,0,.82.94.11.11,0,0,0,.09,0,.12.12,0,0,0,.09,0,.17.17,0,0,0,0-.2,1.8,1.8,0,0,1-.2-.19l-.15-.17-.25-.3-.34-.43-.06-.08-.17-.24-.23-.32-.2-.33-.12-.19-.12-.22a.12.12,0,0,0-.11-.07l-.06,0m.6.78h0' />
            <path d='M527.31,324.93a.15.15,0,0,0,0,.19c.06.1.12.21.19.31s.09.14.14.21l.29.43a.86.86,0,0,0,.07.1l.17.22.33.42c.07.1.16.2.24.3l.25.31c.2.23.39.46.6.68a2.72,2.72,0,0,0,.29.3.13.13,0,0,0,.09,0,.18.18,0,0,0,.09,0,.17.17,0,0,0,0-.2,2.65,2.65,0,0,1-.33-.34l-.27-.3-.2-.23-.1-.12-.33-.41c-.24-.28-.46-.58-.69-.88-.09-.13-.18-.26-.26-.39l-.16-.23-.2-.32a.12.12,0,0,0-.11-.07l-.06,0' />
            <path d='M527.31,324.26a.14.14,0,0,0,0,.19l.27.56a4.42,4.42,0,0,0,.34.54c.12.17.25.33.38.5s.27.31.41.47l.6.69c.26.28.51.57.78.85a.13.13,0,0,0,.18,0,.16.16,0,0,0,0-.2c-.43-.45-.84-.92-1.26-1.4l-.37-.42a7.36,7.36,0,0,1-.55-.7,4.14,4.14,0,0,1-.3-.5c-.09-.17-.18-.35-.26-.53a.12.12,0,0,0-.11-.07l-.06,0m.73,1.08v0' />
            <path d='M527.64,326.62a.15.15,0,0,0,0,.19,6.28,6.28,0,0,0,.44.76c.09.12.18.24.28.35s.2.21.3.31a3.72,3.72,0,0,0,.51.46.12.12,0,0,0,.17-.05.15.15,0,0,0,0-.19l-.15-.12a.63.63,0,0,1-.12-.11l-.16-.15-.27-.28a3.19,3.19,0,0,1-.33-.4v0h0a7.38,7.38,0,0,1-.41-.72.11.11,0,0,0-.11-.07.07.07,0,0,0-.06,0m.58.77h0' />
            <path d='M527.33,326.45a.13.13,0,0,0-.05.09.12.12,0,0,0,0,.1,5.85,5.85,0,0,0,.4.79,4.84,4.84,0,0,0,.57.7l.28.3a1.76,1.76,0,0,0,.3.24l.18.11a.12.12,0,0,0,.17,0,.15.15,0,0,0,0-.19,1.65,1.65,0,0,1-.31-.2c-.11-.1-.21-.21-.31-.31a5.35,5.35,0,0,1-.58-.68,4.27,4.27,0,0,1-.44-.85.13.13,0,0,0-.11-.06h-.07' />
            <path d='M528.53,327.2a.15.15,0,0,0,0,.2c.09.11.18.23.28.34l.31.31a6.26,6.26,0,0,0,.59.46c.2.13.4.26.61.37a.12.12,0,0,0,.1,0,.1.1,0,0,0,.07-.07.14.14,0,0,0,0-.19,3.38,3.38,0,0,1-.41-.24l-.23-.15a4.7,4.7,0,0,1-.44-.34,3.61,3.61,0,0,1-.35-.33c-.11-.12-.21-.25-.31-.38a.12.12,0,0,0-.18,0' />
            <path d='M529.29,327.37a.16.16,0,0,0-.06.08.14.14,0,0,0,0,.11,5.4,5.4,0,0,0,.33.46l.19.19.12.12.3.27.17.16.16.12a2.92,2.92,0,0,0,.37.24.13.13,0,0,0,.17-.05.15.15,0,0,0,0-.19l-.27-.17-.2-.14-.32-.28-.17-.17-.11-.1-.12-.13a1.64,1.64,0,0,1-.21-.25l-.15-.22a.13.13,0,0,0-.07-.06h0l-.06,0m.31.25h0m1,1h0' />
            <path d='M531.82,318.23l-.25,1-.24.93c-.08.29-.15.59-.22.88-.14.55-.27,1.11-.37,1.67,0,.22-.08.45-.12.67s-.07.45-.1.68,0,.45-.07.68,0,.46,0,.69a12.37,12.37,0,0,0,.05,1.36,7.39,7.39,0,0,0,.22,1.33,2.91,2.91,0,0,0,.16.5,1.88,1.88,0,0,0,.24.44.11.11,0,0,0,.09,0,.12.12,0,0,0,.09,0,.18.18,0,0,0,0-.2h0l-.11-.19a1.89,1.89,0,0,1-.09-.21,6.25,6.25,0,0,1-.31-1.36c0-.48-.07-1-.08-1.45a12.34,12.34,0,0,1,.08-1.5v0c0-.45.13-.9.21-1.34s.19-.89.28-1.33l.1-.37.15-.63.42-1.64.15-.53a.16.16,0,0,0-.09-.18h0a.14.14,0,0,0-.13.1m-.75,10.2v0' />
          </g>
        </g>
      </motion.g>
    </>
  )
}

export default Blu3
