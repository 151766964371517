import { useEffect, useState, useMemo, useRef, useContext } from 'react'
import { motion } from 'framer-motion'

import Logo from './items/logo.js'

import Wrap from './wrap'
import Chef from './items/chef/chef'
import Celestial from './items/celestial'
import Spoons from './items/spoons'

const Head = ({ user }) => {
  const spring = {
    transition: {
      type: 'spring',
      stiffness: 100,
    },
  }

  const navs = [
    { item: <Logo />, label: 'logo' },
    { item: <Chef user={user} />, label: 'account' },
    { item: <Celestial />, label: 'mode' },
    // { item: <Spoons />, label: 'spoons' },
  ]
  return (
    <>
      <header>
        <motion.div>
          {navs.map((d, i) => (
            <Wrap d={d} i={i} spring={spring} />
          ))}
        </motion.div>
      </header>
    </>
  )
}

export default Head
