import { useRef, useState, useEffect, useContext } from 'react'
import './ingredientlist.css'
import ListItem from './listItem'
import { Custom } from '../../context/custom'

const List = ({ t, f }) => {
  const { value, setValue } = useContext(Custom)

  const pantry = ['salt', 'pepper']

  return (
    <section className={'ingredient-list'}>
      <div className='ingredient-list'>
        {value ? (
          value.flat
            .filter((d) => !!d.ingredient)
            .filter((d) => !pantry.includes(d.ingredient))
            .map((d, i) => {
              return <ListItem d={d} t={t} f={f} />
            })
        ) : (
          <></>
        )}
      </div>
    </section>
  )
}

export default List
