import { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react'
import { signInWithGoogle } from '../../../../../pantry/pantry'

import { motion } from 'framer-motion'
import ChefItems from './chefitems'
import { Theme } from '../../../../../context/theme'
import { themes } from '../../../../../../media/theme/colors'
import './chef.css'
const Chef = ({ user }) => {
  const { theme, setTheme } = useContext(Theme)
  const [saved, setSaved] = useState(null)
  const [chef, setChef] = useState(0)

  const green = user ? themes[theme].blu1 : themes[theme].gry1

  return (
    <>
      <div
        className='chef'
        onClick={() => (user ? setChef(!chef) : signInWithGoogle())}
      >
        <motion.svg className='chef' viewBox='0 0 68 68'>
          <motion.g animate={{ fill: green, stroke: green }}>
            <motion.path
              className='chef'
              d='M51.29,11.74C40.47.59,22.07,1.86,12.71,11.34c-10.92,11.07-7,31.41,2.86,40.92C26.78,63,40.14,62.44,50.4,50.75,59.81,40,61.13,21.88,51.29,11.74ZM44,43.83a1.5,1.5,0,0,1-1.9.94c-.73-.25-1.55.42-3.11,1.9-1.77,1.67-4.05,3.83-7.36,3.83-2.57,0-5.77-1.31-9.83-5.17a1.5,1.5,0,1,1,2.06-2.17C31.06,50,33.89,47.33,36.9,44.49c1.69-1.6,3.6-3.41,6.13-2.56A1.5,1.5,0,0,1,44,43.83Z'
            />
            <motion.path
              strokeWidth={6}
              stroke={themes[theme].bak0}
              strokeLinecap={'round'}
              d='M24.6,44.38c11,10.46,13.37-1,17.39-.79'
            />
          </motion.g>
        </motion.svg>

        <ChefItems theme={themes[theme]} chef={chef} />
      </div>
    </>
  )
}

export default Chef
