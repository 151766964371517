import React, { useState, useEffect, useContext, useRef } from 'react'
import _ from 'lodash'
import { AnimatePresence, motion } from 'framer-motion'
import { hierarchy } from 'd3'

import { Link } from 'react-router-dom'
import { Theme } from '../../../context/theme'
import { themes } from '../../../../media/theme/colors.js'

import Save from './save'
import Love from './love'

import './plate.css'

const Plate = ({ d, i }) => {
  const tree = hierarchy(d.recipe)
  const { theme } = useContext(Theme)
  const t = themes[theme]
  const ref = useRef()
  const [here, setHere] = useState(null)
  return (
    <motion.div
      key={d.id}
      ref={ref}
      className='plate-wrap'
      onMouseEnter={() => setHere(1)}
      onMouseLeave={() => setHere(0)}
    >
      <Link to={'/r/' + d.id} draggable={false}>
        <div className={'plate-mask'}>
          <motion.img
            src={d.imgmain}
            draggable={false}
            animate={{ scale: here ? 1 : 1.04 }}
            transition={{ ease: 'easeOut', duration: 0.3 }}
          />
        </div>
      </Link>
      <div className={'plate-flex'}>
        <Link to={'/r/' + d.id} draggable={false}>
          <motion.div className='plate-main'>
            <motion.h1 className='plate font1' style={{ color: t.txt1 }}>
              {d.name}
            </motion.h1>
          </motion.div>
        </Link>

        <motion.div className='plate-acts font1'>
          <Love d={d} />
        </motion.div>
      </div>
    </motion.div>
  )
}

export default Plate
