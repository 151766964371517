import { useEffect, useState } from 'react'

import { motion } from 'framer-motion'

const ChefWRap = ({ d }) => {
  return (
    <>
      <motion.div className='chef-wrap' onClick={d.do}>
        <motion.div className='chef-item'>{d.item}</motion.div>
      </motion.div>
    </>
  )
}

export default ChefWRap
