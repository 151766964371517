import anime from 'animejs/lib/anime.es'
import { signInWithGoogle } from '../../../../pantry/pantry'

const shakey = (ref) => {
  setTimeout(() => {
    signInWithGoogle()
  }, 380)
  let shake = 1
  let rot = 3
  let ease = 'easeOutQuart'
  let duration = 80
  anime({
    targets: ref,
    transformOrigin: '50% 70%',
    scale: [
      {
        value: 0.9,
        duration: duration * 5.5,
        easing: 'easeOutQuad',
      },
      {
        value: 1,
        duration: duration * 2,
        easing: 'easeInQuad',
      },
    ],
    rotate: [
      {
        value: -rot,
        easing: ease,
        duration: duration,
      },
      {
        value: rot,
        easing: ease,
        duration: duration,
      },
      {
        value: -rot,
        easing: ease,
        duration: duration,
      },
      {
        value: rot,
        easing: ease,
        duration: duration,
      },
      {
        value: -rot,
        easing: ease,
        duration: duration,
      },
      {
        value: rot,
        easing: ease,
        duration: duration,
      },
      {
        value: 0,
        easing: ease,
        duration: duration,
      },
    ],
    translateX: [
      {
        value: -shake,
        easing: ease,
        duration: duration,
      },
      {
        value: shake,
        easing: ease,
        duration: duration,
      },
      {
        value: -shake,
        easing: ease,
        duration: duration,
      },
      {
        value: shake,
        easing: ease,
        duration: duration,
      },
      {
        value: -shake,
        easing: ease,
        duration: duration,
      },
      {
        value: shake,
        easing: ease,
        duration: duration,
      },
      {
        value: 0,
        easing: ease,
        duration: duration,
      },
    ],
  })
}

export { shakey }
