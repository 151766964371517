import { useState, useEffect } from 'react'

import {
  randomUniform,
  randomInt,
  randomNormal,
  interval,
  format,
  scaleOrdinal,
  scaleThreshold,
} from 'd3'
import { motion } from 'framer-motion'
import math from 'mathjs'
const Box = ({ choice, v, i, E, t }) => {
  const [approach, setApproach] = useState(0)

  useEffect(() => {
    setInterval(() => {
      setApproach((approach) => approach + v.a)
    }, v.p + i * v.d)
  }, [])

  const [R1, setR1] = useState(null)
  const w = (a) => (
    <motion.img
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
      style={{
        width: 62,
        filter: t.light ? '' : 'invert(1)',
      }}
      src={`/el/${a}.png`}
    ></motion.img>
  )
  const r1 = randomNormal(approach, 0.3)()
  const r2 = (b) => randomInt(0, b)()
  var sc = scaleThreshold([0.5, 0.7], [w(E[r2(E.length)]), choice[1]])
  useEffect(() => {
    setR1(sc(r1))
  }, [approach])

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: (i ^ 2) * v.de }}
        style={{ color: t.opp }}
      >
        {R1}
      </motion.div>
    </>
  )
}

export default Box
