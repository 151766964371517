import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { useState, useEffect, useRef } from 'react'

const Orn2 = ({ t, fade, s, a, ss }) => {
  return (
    <>
      <motion.g
        {...fade}
        animate={{
          x: a ? (ss ? -60 : -22) : 0,
          opacity: s ? 1 : 0.2,
          filter: s ? 'blur(0px)' : 'blur(4px)',
        }}
      >
        <g
          fill={t.orn1}
          style={{
            transform: ss ? '' : 'scale(.75) translate(-191px, 0px)',
          }}
        >
          <path d='M571,487.74h-2.17v-.68c0-.24,0-2.15,0-5.24v-4.45l-.47,1.34c-.41,1.14-.83,2.34-1.12,3.16l-.36,1a.62.62,0,0,0,0,.1,2.23,2.23,0,0,1-.14.34.36.36,0,0,0,0,.09c0,.1-.06.12-.11.14a1.83,1.83,0,0,1-.59.08h0a4.91,4.91,0,0,1-.85-.09.62.62,0,0,1-.16,0,1,1,0,0,1,0-.12s0-.07,0-.1-.07-.25-.11-.37l0-.12c-.07-.21-.13-.42-.19-.61q-.19-.69-.42-1.38c-.23-.65-.61-1.35-.89-2.07l-.45-1.19,0,1c0,2.73-.07,9.17-.07,9.23h-2.17V472.37h2.18l.18,0h0s2.59,6.67,2.78,7.19l.24.64.22-.65c.17-.5.36-1.22.54-1.92s1.93-5.26,1.93-5.26l.15,0H571Z' />
          <path d='M581.94,487.66a.23.23,0,0,1-.17.08h-1.85a.22.22,0,0,1-.21-.16l-.08-.39h0l-.06-.3v0l0-.19c-.26-1.27-.4-2-.44-2.12a1.45,1.45,0,0,0-.06-.19l0-.14-.15,0h-.19c-.19,0-1.1,0-1.83,0h-.53c-.62,0-.75.06-.82.11s-.11.15-.15.36l-.09.41c-.1.43-.19.89-.29,1.35,0,.26-.1.51-.16.76l-.07.4a.21.21,0,0,1-.21.17H572.6a.21.21,0,0,1-.17-.08.19.19,0,0,1,0-.18l.11-.4h0l.06-.28c0-.09,0-.19.08-.31l0-.07c.23-.9.44-1.82.64-2.72.11-.45.22-.93.33-1.4.17-.74.35-1.47.52-2.21v0c.22-.93.47-2,.7-3,.12-.5.24-1,.35-1.52s.24-1,.35-1.54l.07-.3h0l.21-1a.45.45,0,0,1,.43-.35h1.65a.45.45,0,0,1,.42.32l.06.19v0c.05.12.09.28.14.45v0c.38,1.42,1.79,7.41,2.55,10.62.22.93.38,1.59.42,1.74l.06.23c.07.32.15.65.22,1l.15.55A.19.19,0,0,1,581.94,487.66Zm-3.53-5.55,0-.28c0-.17-.06-.35-.1-.55s-.08-.47-.16-1c-.2-1.26-.54-3.36-.67-3.9l-.26-1-.22,1c0,.16-.13.79-.26,1.58-.17,1-.38,2.31-.46,2.69a1.9,1.9,0,0,1-.09.29c0,.11-.07.22-.09.32s-.09.39-.13.58l-.06.29h.32l.36,0,.27,0h1.59Z' />
          <path d='M591.45,484.63a3.86,3.86,0,0,1-2.53,3,5.08,5.08,0,0,1-1.55.26h-.12a4.24,4.24,0,0,1-3.07-1.12,4.49,4.49,0,0,1-1.29-2.84c0-.09,0-.18,0-.26s0-.35,0-.55,0-.28,0-.41a.15.15,0,0,1,.14-.13h1.77a.15.15,0,0,1,.14.13l0,.58a3.24,3.24,0,0,0,.37,1.51,2,2,0,0,0,1.65,1,1.92,1.92,0,0,0,1.74-.64,2.7,2.7,0,0,0-.41-3.72,6.6,6.6,0,0,0-1.71-.93,11.62,11.62,0,0,1-1.06-.49,4.82,4.82,0,0,1-2.06-2,4.36,4.36,0,0,1-.15-3.47,3.48,3.48,0,0,1,2.44-2.12,4.54,4.54,0,0,1,3.92.75,4.94,4.94,0,0,1,1.64,3.36v.14l0,.23s0,0,0,0a.05.05,0,0,1,0,0h-1.95c-.09-2.24-1.6-2.47-2.1-2.55h-.08a1.51,1.51,0,0,0-1.53.47,1.72,1.72,0,0,0-.4,1.88,3.24,3.24,0,0,0,1.95,1.66l.28.12.11,0a7.26,7.26,0,0,1,2.37,1.36h0A5.2,5.2,0,0,1,591.45,484.63Z' />
          <path d='M602,479.83v7.85a0,0,0,0,1,0,.05h-2.12a.06.06,0,0,1-.06-.05V487c0-.67,0-1.83,0-2.25v-.86h0c0-.72,0-2.34,0-3.05v-.22h-.23c-.53,0-1.57,0-2.26,0H596l-.2,0,0,.2V481c0,1.18,0,2.36,0,3.49,0,.63-.06,3.15-.06,3.18a.06.06,0,0,1-.06.05h-2a.06.06,0,0,1-.06-.05V472.46a.11.11,0,0,1,.1-.1h2a.11.11,0,0,1,.1.1v0a.54.54,0,0,0,0,.19,1.18,1.18,0,0,1,0,.2v0c.07.47.06,3.3,0,4.11,0,.08,0,.17,0,.29a7.17,7.17,0,0,0,0,1l0,.21h1.28c.84,0,1.8,0,2.53,0h.23v-.24c0-.08,0-.18,0-.27h0v-.38c0-.26,0-.81,0-1.33v-3.89a.08.08,0,0,1,.08-.09h2a.09.09,0,0,1,.08.09C602,473.58,602,477.46,602,479.83Z' />
          <path d='M560.3,501.68h3.37v5.43h2.45v1.2h-6.21v-1.2h2.49v-4.23h-2.1Zm1.82-1.28v-1.93h1.55v1.93Z' />
          <path d='M569.74,499.77H571v2.06h3.34V503H571v2.46c0,.27,0,.47,0,.58a2.46,2.46,0,0,0,.07.46.94.94,0,0,0,.16.41,1.17,1.17,0,0,0,.34.22,1.35,1.35,0,0,0,.57.1,3.56,3.56,0,0,0,.84-.1,6,6,0,0,0,.72-.21l.84-.33.47-.2.48,1.16a8.32,8.32,0,0,1-3.24.94,2.57,2.57,0,0,1-1.92-.66,2,2,0,0,1-.5-1,6.9,6.9,0,0,1-.11-1.4V503h-1v-1.2h1Z' />
          <path d='M579.73,499.77h2.11l-1.8,4h-1.59Z' />
          <path d='M591.08,501.34l1.14.19a7.3,7.3,0,0,0-.14,1.28,4.75,4.75,0,0,0,.14,1.05l-1.14.16a.56.56,0,0,1-.07-.19l-.12-.34c-.18-.5-.77-.76-1.79-.76s-1.53.28-1.53.82a.65.65,0,0,0,.14.44,1,1,0,0,0,.49.25,3.42,3.42,0,0,0,.54.11c.12,0,.34,0,.64,0l1.15.09a4.62,4.62,0,0,1,.7.13,1.73,1.73,0,0,1,.64.3,1.67,1.67,0,0,1,.71,1.4,1.91,1.91,0,0,1-.83,1.57,3.67,3.67,0,0,1-3.06.49,2.48,2.48,0,0,1-.61-.26,3.7,3.7,0,0,1-.47-.35l-.18.76-1.14-.07a8.81,8.81,0,0,0,.15-1.39,4.83,4.83,0,0,0-.15-1.14l1.14-.14a2,2,0,0,0,.59,1.08,2.08,2.08,0,0,0,1.41.39,3,3,0,0,0,1.32-.26.78.78,0,0,0,.51-.71.5.5,0,0,0-.38-.51,6.59,6.59,0,0,0-1.4-.18,5.46,5.46,0,0,1-2.27-.48,2,2,0,0,1-.69-.68,1.73,1.73,0,0,1-.27-.93,1.68,1.68,0,0,1,.85-1.51,3.5,3.5,0,0,1,1.84-.53,3.14,3.14,0,0,1,1.95.69Z' />
          <path d='M610.07,503.82v3.29h1.26v1.2h-2.54v-.7a4.09,4.09,0,0,1-2.42.91,2.5,2.5,0,0,1-1.83-.7,2.12,2.12,0,0,1-.73-1.59,1.91,1.91,0,0,1,.16-.75,2.89,2.89,0,0,1,.51-.78,2.56,2.56,0,0,1,1-.63,4.63,4.63,0,0,1,1.6-.25c.41,0,1,0,1.67.11a2.39,2.39,0,0,0,0-.43,2.1,2.1,0,0,0-.14-.39.77.77,0,0,0-.44-.36,2.33,2.33,0,0,0-.82-.12,7,7,0,0,0-2.57.67l-.5-1.17a8.49,8.49,0,0,1,3.08-.76,2.83,2.83,0,0,1,2,.64A2.4,2.4,0,0,1,610.07,503.82Zm-1.28,2.12v-.76a12.61,12.61,0,0,0-1.52-.16,3.73,3.73,0,0,0-1,.12,1.87,1.87,0,0,0-.64.26,1.18,1.18,0,0,0-.33.34,1.44,1.44,0,0,0-.14.31,1.5,1.5,0,0,0,0,.2.88.88,0,0,0,.37.7,1.3,1.3,0,0,0,.86.31A3.33,3.33,0,0,0,608.79,505.94Z' />
          <path d='M613.24,499.07l3.68-.25v8.29h2.46v1.2h-6.19v-1.2h2.46v-7l-2.29.15Z' />
          <path d='M621.73,499.07l3.68-.25v8.29h2.46v1.2h-6.2v-1.2h2.47v-7l-2.29.15Z' />
          <path d='M640.19,501.68h3.37v5.43H646v1.2h-6.22v-1.2h2.5v-4.23h-2.1ZM642,500.4v-1.93h1.55v1.93Z' />
          <path d='M647.63,501.68h2.46v.61a3.87,3.87,0,0,1,2.13-.82,2.23,2.23,0,0,1,1.74.74,2.71,2.71,0,0,1,.66,1.9v3h1.19v1.2h-3.7v-1.2h1.23v-2.94a1.71,1.71,0,0,0-.29-1,.93.93,0,0,0-.8-.41,2,2,0,0,0-.82.19,3.9,3.9,0,0,0-.65.32l-.69.49v3.38h1.12v1.2H647.6v-1.2h1.22v-4.23h-1.19Z' />
          <path d='M561,517.77h1.27v2.06h3.34V521h-3.34v2.46c0,.27,0,.47,0,.58a3.72,3.72,0,0,0,.07.46.83.83,0,0,0,.17.41,1,1,0,0,0,.33.22,1.35,1.35,0,0,0,.57.1,3.71,3.71,0,0,0,.85-.1,5.8,5.8,0,0,0,.71-.21l.84-.33.47-.2.48,1.16a8.32,8.32,0,0,1-3.24.94,2.57,2.57,0,0,1-1.92-.66,2,2,0,0,1-.5-1,7.71,7.71,0,0,1-.11-1.4V521h-1v-1.2h1Z' />
          <path d='M567.58,517l2.7-.21v3.47a3.82,3.82,0,0,1,2.12-.82,2.24,2.24,0,0,1,1.75.74,2.69,2.69,0,0,1,.66,1.89v3H576v1.2H572.3v-1.2h1.23v-3a1.74,1.74,0,0,0-.28-1,.93.93,0,0,0-.82-.41,4.1,4.1,0,0,0-2.15,1v3.38h1.11v1.2h-3.6v-1.2H569v-6.94l-1.32.09Z' />
          <path d='M584.05,524.2l.59,1.13a7.73,7.73,0,0,1-3.8,1.19,3.69,3.69,0,0,1-2.75-1,3.39,3.39,0,0,1-1-2.48,3.51,3.51,0,0,1,3.65-3.56,3.41,3.41,0,0,1,2.56,1,3,3,0,0,1,.93,2.15,7.8,7.8,0,0,1-.05.89h-5.74a2.44,2.44,0,0,0,.17.52,3,3,0,0,0,.33.55,1.63,1.63,0,0,0,.73.53,3,3,0,0,0,1.14.19A6.45,6.45,0,0,0,584.05,524.2Zm-5.52-1.93h4.35a2,2,0,0,0-2.13-1.54A2.18,2.18,0,0,0,578.53,522.27Z' />
          <path d='M594.32,519.68h3.1v1.2h-1.08l.81,3.11h0l1.15-3.11h.89l1.17,3.11h0l.82-3.11h-1.1v-1.2h3v1.2h-.62L601,526.31h-1.15l-1.13-3.19h0l-1.1,3.19h-1.11l-1.5-5.43h-.67Z' />
          <path d='M604.15,519.68h2.9v1.08a7.57,7.57,0,0,1,.69-.61,3.63,3.63,0,0,1,.83-.45,2.93,2.93,0,0,1,1.13-.23,2.47,2.47,0,0,1,.95.18,2.63,2.63,0,0,1,.62.32c.11.1.26.23.42.4l-1.3,1a1.09,1.09,0,0,0-.94-.63,1.27,1.27,0,0,0-.37.07,1.92,1.92,0,0,0-.39.17,2.84,2.84,0,0,0-.39.25c-.13.09-.25.19-.36.28l-.33.3-.27.27a1.8,1.8,0,0,0-.19.2l-.1.1v2.74h2.71v1.2H604v-1.2h1.75v-4.23h-1.62Z' />
          <path d='M613.58,519.68H617v5.43h2.45v1.2h-6.21v-1.2h2.49v-4.23h-2.1Zm1.82-1.28v-1.93H617v1.93Z' />
          <path d='M626.78,519.34l1.14.19a6.45,6.45,0,0,0-.14,1.28,4.22,4.22,0,0,0,.14,1.05l-1.14.16a.56.56,0,0,1-.07-.19l-.12-.34c-.18-.5-.77-.76-1.79-.76s-1.54.28-1.54.82a.65.65,0,0,0,.15.44,1,1,0,0,0,.49.25,3.42,3.42,0,0,0,.54.11c.12,0,.34,0,.64,0l1.15.09a4.62,4.62,0,0,1,.7.13,1.81,1.81,0,0,1,.64.3,1.66,1.66,0,0,1,.7,1.4,1.9,1.9,0,0,1-.82,1.57,3.67,3.67,0,0,1-3.06.49,2.31,2.31,0,0,1-.61-.26,3.7,3.7,0,0,1-.47-.35l-.18.76-1.14-.07a8.81,8.81,0,0,0,.15-1.39,4.83,4.83,0,0,0-.15-1.14l1.14-.14a1.91,1.91,0,0,0,.59,1.08,2.07,2.07,0,0,0,1.4.39,3,3,0,0,0,1.33-.26.78.78,0,0,0,.51-.71.5.5,0,0,0-.38-.51,6.59,6.59,0,0,0-1.4-.18,5.46,5.46,0,0,1-2.27-.48,2,2,0,0,1-.69-.68,1.73,1.73,0,0,1-.27-.93,1.68,1.68,0,0,1,.85-1.51,3.48,3.48,0,0,1,1.84-.53,3.18,3.18,0,0,1,2,.69Z' />
          <path d='M631.8,517.77h1.28v2.06h3.34V521h-3.34v2.46c0,.27,0,.47,0,.58a2.32,2.32,0,0,0,.06.46.93.93,0,0,0,.17.41,1.05,1.05,0,0,0,.34.22,1.34,1.34,0,0,0,.56.1,3.64,3.64,0,0,0,.85-.1,5.8,5.8,0,0,0,.71-.21l.84-.33.47-.2.49,1.16a8.32,8.32,0,0,1-3.24.94,2.57,2.57,0,0,1-1.92-.66,2.07,2.07,0,0,1-.51-1,7.71,7.71,0,0,1-.11-1.4V521h-1v-1.2h1Z' />
        </g>
        <circle
          style={{
            strokeWidth: 2,
            stroke: t.orn1,
            fill: a ? t.orn1 : 'none',
          }}
          cx={ss ? 522.43 : 247}
          cy={ss ? 479.57 : 368}
          r={ss ? 7.05 : 6}
        />
      </motion.g>
    </>
  )
}

export default Orn2
