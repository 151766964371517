import { useState, useContext } from 'react'
import { motion } from 'framer-motion'

import { Theme } from '../../../../context/theme'
import { themes } from '../../../../../media/theme/colors'

const Celestial = ({}) => {
  const { theme, setTheme } = useContext(Theme)

  const [temp, setTemp] = useState(false)

  const celestialPoints = [
    'M31.42,31.22c-12.55-12.6-15-30.6-17.62-28A32.21,32.21,0,0,0,59.36,48.8C62.08,46.08,43.88,43.73,31.42,31.22Z',
    'M50.44,11.2c-11-11-29.22-9.89-38.58-.4A28.15,28.15,0,0,0,51.67,50.6C61.31,40.84,61.33,22.13,50.44,11.2Z',
  ]

  const ease = `
  *{
    transition: fill 2s ease-in, stroke 2s ease-in, color 2s ease-in, background 2s ease-in, background-color 2s ease-in;
  }`
  return (
    <>
      {temp && <style>{ease}</style>}
      <motion.svg
        viewBox='0 0 68 68'
        className={'theme-switch'}
        onMouseDown={() => {
          setTemp(true)
          setTimeout(() => {
            setTemp(false)
          }, 2020)
          setTheme(1 - theme)
          localStorage.setItem('theme', 1 - theme)
        }}
      >
        <motion.path
          animate={{
            d: celestialPoints[theme],
            fill: theme ? themes[theme].orn1 : themes[theme].blu1,
          }}
          transition={{
            d: { type: 'spring', stiffness: 50 },
            fill: { type: 'easeInOut' },
          }}
        />
      </motion.svg>
    </>
  )
}

export default Celestial
