import { useState, useEffect, useContext, useRef } from 'react'
import { Patron } from '../../context/patron'
import { Custom } from '../../context/custom'
import format from '../_formats/formatDesktop.json'
import { Theme } from '../../context/theme'
import { themes } from '../../../media/theme/colors'

import { motion } from 'framer-motion'

import { useLocation } from 'react-router-dom'
import './portion.css'
const Portion = ({ serves }) => {
  const { theme } = useContext(Theme)

  const { value, setValue } = useContext(Custom)
  let location = useLocation()
  let recipeID = location.pathname.split('/')[2]

  let recipePortion = localStorage.getItem(recipeID + 'portion')

  const [portion, setPortion] = useState(
    +recipePortion == 0.25 ||
      +recipePortion == 0.5 ||
      +recipePortion == 1 ||
      +recipePortion == 2 ||
      +recipePortion == 4
      ? +recipePortion
      : 1
  )

  useEffect(() => {
    setValue((value) => ({
      ...value,
      portion: portion,
    }))
    localStorage.setItem(recipeID + 'portion', +portion)
  }, [portion])

  const twoX = useRef()
  const halfX = useRef()
  const curr = useRef()

  return (
    <>
      <div className={'portion'}>
        <div className={'portion-control'}>
          <motion.button
            style={{
              top: 2,
              pointerEvents: portion >= 4 ? 'none' : 'all',
              cursor: portion >= 4 ? 'default' : 'pointer',
            }}
            onMouseDown={() => {
              setPortion(portion >= 4 ? portion : portion * 2)
            }}
            whileTap={{ scale: 0.9 }}
          >
            <svg
              ref={twoX}
              className={'portion-control-svg'}
              viewBox='0 0 64 64'
              width={20}
              height={20}
            >
              <g>
                <line
                  stroke={themes[theme].txt2}
                  strokeWidth={10}
                  strokeLinecap={'round'}
                  x1={32}
                  y1={5}
                  x2={32}
                  y2={59}
                />
                <line
                  stroke={themes[theme].txt2}
                  strokeWidth={10}
                  strokeLinecap={'round'}
                  str
                  x1={5}
                  y1={32}
                  x2={59}
                  y2={32}
                />
              </g>
            </svg>
          </motion.button>

          <div
            ref={curr}
            className={'portion-cur '}
            style={{ color: themes[theme].txt2 }}
          >
            {serves !== undefined ? serves * portion : portion}
          </div>
          <motion.button
            style={{
              bottom: -4,
              pointerEvents: portion <= 0.25 ? 'none' : 'all',
              cursor: portion <= 0.25 ? 'default' : 'pointer',
            }}
            onMouseDown={() => {
              setPortion(portion <= 0.25 ? portion : portion * 0.5)
            }}
            whileTap={{ scale: 0.9 }}
          >
            <svg
              ref={halfX}
              className={'portion-control-svg'}
              viewBox='0 0 64 64'
              width={20}
              height={20}
            >
              <g>
                <line
                  stroke={themes[theme].txt2}
                  strokeWidth={10}
                  strokeLinecap={'round'}
                  x1={5}
                  y1={14}
                  x2={59}
                  y2={14}
                />
              </g>
            </svg>
          </motion.button>
        </div>
        <svg
          width={25}
          style={{ margin: '2px 0px 0px 6px' }}
          viewBox='0 0 64 64'
          onMouseDown={() => {
            setPortion(1)
          }}
        >
          <g>
            <circle
              cx={32}
              cy={22}
              r={12}
              stroke={themes[theme].txt2}
              strokeWidth={7.5}
              fill={'none'}
            ></circle>
            <path
              stroke={themes[theme].txt2}
              strokeWidth={7.5}
              strokeLinecap={'round'}
              fill={'none'}
              d={`M 12 62 A 1 1 0 0 1 52 62`}
            ></path>
          </g>
        </svg>
      </div>
    </>
  )
}
export default Portion
