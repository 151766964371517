import firebase from 'firebase/compat/app'
import restaurant_safe from './restaurant_safe'

import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithPopup, GithubAuthProvider } from 'firebase/auth'

const app = firebase.initializeApp(restaurant_safe)
const auth = app.auth()
const db = app.firestore()
const googleProvider = new firebase.auth.GoogleAuthProvider()
const githubProvider = new GithubAuthProvider()

const githubAuth = getAuth()

// const signInWithGithub = () => {
//
//
//   signInWithPopup(githubAuth, githubProvider)
//     .then((result) => {
//       const credential = GithubAuthProvider.credentialFromResult(result);
//       const token = credential.accessToken;
//       const user = result.user;
//     }).catch((error) => {
//
//       const errorCode = error.code;
//       const errorMessage = error.message;
//       const email = error.email;
//       const credential = GithubAuthProvider.credentialFromError(error);
//     });
//
// }

const signInWithGithub = async () => {
  try {
    const res = await signInWithPopup(githubAuth, githubProvider)
    const credential = GithubAuthProvider.credentialFromResult(res)
    const token = credential.accessToken
    const user = res.user
    const query = await db
      .collection('users')
      .where('uid', '==', user.uid)
      .get()
    if (query.docs.length === 0) {
      await db.collection('users').add({
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      })
    }
    return [true, res.user]
  } catch (err) {
    return [false, err]
  }
}

const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider)
    const user = res.user
    const query = await db
      .collection('users')
      .where('uid', '==', user.uid)
      .get()
    if (query.docs.length === 0) {
      await db.collection('users').add({
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      })
    }
    return [true, res.user]
  } catch (err) {
    return [false, err]
  }
}

const registerWithEmailAndPassword = async (email, password) => {
  try {
    let res = await auth.createUserWithEmailAndPassword(email, password)
    return [true, res.user]
  } catch (err) {
    return [false, err]
  }
}

const signInWithEmailAndPassword = async (email, password) => {
  try {
    let res = await auth.signInWithEmailAndPassword(email, password)
    return [true, res.user]
  } catch (err) {
    return [false, err]
  }
}

const sendPasswordResetEmail = async (email) => {
  try {
    await auth.sendPasswordResetEmail(email)
    alert('Password reset link sent!')
  } catch (err) {
    alert(err.message)
  }
}

const logout = () => {
  auth.signOut()
}

export {
  app,
  auth,
  db,
  signInWithGoogle,
  signInWithGithub,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  logout,
}
