import { format, precisionRound, precisionFixed } from 'd3-format'
import { scaleLinear, scaleThreshold } from 'd3-scale'
import convert from 'convert-units'

import { round, evaluate, fraction } from 'mathjs'
const f = format('')

var frac = scaleLinear()
  .domain([
    0.0625, 0.0825, 0.125, 0.165, 0.25, 0.33, 0.375, 0.5, 0.625, 0.66, 0.75,
    0.875, 1.25, 1.32, 1.5, 2.5, 2.64, 5.28,
  ])
  .range([
    '1/16',
    '1/12',
    '1/8',
    '1/6',
    '1/4',
    '1/3',
    '3/8',
    '1/2',
    '5/8',
    '2/3',
    '3/4',
    '7/8',
    '1 1/4',
    '1 1/3',
    '1 1/2',
    '2 1/2',
    '2 2/3',
    '5 1/3',
  ])

const clean = (a, b) => {
  a = Number.isInteger(a) ? a : frac(a)
  a = a == undefined ? '' : a
  return a + ' ' + (b || '')
}

export { clean }
