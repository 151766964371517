import { motion } from 'framer-motion'
import { ReactComponent as GoogleSVG } from '../../../../../../media/externals/google.svg'
import { ReactComponent as AppleSVG } from '../../../../../../media/externals/apple.svg'
import { ReactComponent as GithubSVG } from '../../../../../../media/externals/github.svg'
import ChefWrap from './chefwrap'
import { logout } from '../../../../../pantry/pantry'

const ChefItems = ({ theme, chef, spring }) => {
  const out = (
    <motion.svg viewBox='0 0 64 64'>
      <motion.path
        fill={theme.gry1}
        d='M60.42,26C55.77,10.11,40.71,1.92,25.53,4.68S1.74,22.86,4.23,36C7.15,51.22,20.79,61.62,36.08,58.81S64.79,40.91,60.42,26ZM48,46.07a2,2,0,0,1-1.67.9,2,2,0,0,1-1.1-.34c-3.77-2.5-8.68-7.5-13.37-12.61C27.47,38.53,21.79,44.36,21.4,46.1a2,2,0,0,1-1.8,2.07h-.2a2,2,0,0,1-2-1.8c-.27-2.71,3-6.39,11.79-15.32l-2.63-2.93c-3.35-3.75-7.53-8.42-8.61-9a2,2,0,0,1-1.18-2.54,2,2,0,0,1,2.59-1.2C21,16,23.31,18.48,29.55,25.46L32,28.2c4.41-4.53,8.87-9.25,11-12.63a2,2,0,0,1,3.39,2.14C44,21.47,39.44,26.3,34.7,31.16c4.56,5,9.3,9.84,12.77,12.14A2,2,0,0,1,48,46.07Z'
      />
    </motion.svg>
  )
  const chefs = [
    { item: out, label: 'logout', do: () => logout() },
    // { item: <GithubSVG />, label: 'mode', sign: () => signInWithGithub() },
    // { item: <AppleSVG />, label: 'mode', sign: () => {} },
  ]

  return (
    <motion.div
      className={'chef-items'}
      initial={{ height: '0px' }}
      animate={{
        height: chef ? 'auto' : '0px',
      }}
    >
      {chefs.map((d, i) => (
        <ChefWrap d={d} spring={spring} />
      ))}
    </motion.div>
  )
}

export default ChefItems
